import {
  SET_DESTINATIONS,
  LOADING_DESTINATIONS,
  LOADED_DESTINATIONS,
} from './actions';

export default function setDestinationNames(state = { destinations: [], loading: true }, action) {
  switch (action.type) {
    case SET_DESTINATIONS:
      return { ...state, destinations: action.payload };
    case LOADING_DESTINATIONS:
      return { ...state, loading: true };
    case LOADED_DESTINATIONS:
      return { ...state, loading: false };
    default:
      return state;
  }
}
