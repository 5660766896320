import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundDiv } from '@keydotco/key-components';

import InfoSectionStepCard from '../InfoSectionStepCard';
import { track } from '../../services/analytics';

import './styles.css';

const LocalExperiencesSection = ({ isStep3, analyticsContext }) => {
  const catamaranImage = 'https://static.key.co/img/home/catamaran.jpg';
  const beachDayImage = 'https://img.key.co/e7ccc73a3c5b986fba399b0b121d2214.jpg';
  const skiingImage = 'https://static.key.co/img/home/family-skiing.jpg';

  const trackClick = (name) => track('link_click', {
    cta: name, section: 'Local Experiences', ...analyticsContext,
  });

  const Collage = () => (
    <div className="experience-card-container">
      <BackgroundDiv
        imageURL={catamaranImage}
        className="experience-card"
        screenPercentage={0.5}
        onClick={() => trackClick('Catamaran Tour')}
        withGradient
      >
        <p>Catamaran Tours</p>
      </BackgroundDiv>
      <div className="experience-card-column">
        <BackgroundDiv
          imageURL={beachDayImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('Beach Day Necessities')}
          withGradient
        >
          <p>Beach Day Necessities</p>
        </BackgroundDiv>
        <BackgroundDiv
          imageURL={skiingImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('Ski + Snowboard Lessons')}
          withGradient
        >
          <p>Ski + Snowboard Lessons</p>
        </BackgroundDiv>
      </div>
    </div>
  );

  return (
    <div>
      <div className="homepage-section local-experiences-section container">
        <Collage />
        <div className="info-step-container">
          <InfoSectionStepCard
            stepNumber={isStep3 ? '3' : undefined}
            header="Bask in the sun on a catamaran. Hit the slopes with a private instructor. Gather your friends for a day out at the beach."
            stepTitle={isStep3 ? 'Add Local Experiences' : 'Local Experiences'}
            subtext="We&apos;re here to make moments special, and help you experience the best a destination has to offer."
            images={[
              { url: catamaranImage, title: 'Catamaran Tours' },
              { url: beachDayImage, title: 'Beach Day Necessities' },
              { url: skiingImage, title: 'Ski + Snowboard Lessons' },
            ]}
            collage={<Collage />}
          />
        </div>
      </div>
    </div>
  );
};

LocalExperiencesSection.propTypes = {
  isStep3: PropTypes.bool,
  analyticsContext: PropTypes.object,
};

LocalExperiencesSection.defaultProps = {
  isStep3: false,
  analyticsContext: {},
};

export default LocalExperiencesSection;
