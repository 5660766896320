import React from 'react';
import PropTypes from 'prop-types';

import PartnerLogo from './PartnerLogo';
import './PartnerPageLink.css';

const PartnerPageLink = ({
  homePageUrl, loading, logo, name,
}) => {
  const partnerLogo = (<PartnerLogo loading={loading} logo={logo} name={name} />);
  if (!homePageUrl) return partnerLogo;
  return (
    <a
      className="partner-homepage-link"
      target="_blank"
      rel="noopener noreferrer"
      href={homePageUrl}
    >
      {partnerLogo}
    </a>
  );
};

PartnerPageLink.propTypes = {
  homePageUrl: PropTypes.string,
  logo: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
};

PartnerPageLink.defaultProps = {
  homePageUrl: '',
  logo: '',
  loading: true,
  name: '',
};

export default PartnerPageLink;
