import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';
import { BackgroundDiv, Card } from '@keydotco/key-components';
import http from '../../services/util/http';
import { track } from '../../services/analytics';

import './styles.css';

/* eslint-disable react/jsx-no-target-blank */

const FeaturedDestinationsSection = ({ analyticsContext }) => {
  const [featuredDestinations, setFeaturedDestinations] = useState((new Array(5)).fill(null));
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    http.get('/api/featured-destinations')
      .then((resp) => {
        if (resp.body) {
          setFeaturedDestinations(resp.body);
          setIsLoading(false);
        }
        return resp.body;
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  return (
    <section className="container featured-destinations">
      <header>
        <h2>Discover your home<wbr /> away from home</h2>
        <p>
          We hand select each home to ensure you experience
          unforgettable moments no matter where you travel.
        </p>
      </header>
      <div className="card-grid">
        {
          featuredDestinations.map((dest) => (
            isLoading ? (
              <Card className="featured-destination-card">
                <Skeleton variant="rect" className="hero-image" />
                <div className="label">
                  <Skeleton variant="text" className="destination-name" width="150px" />
                  <Skeleton variant="text" className="listings-count" width="130px" />
                </div>
              </Card>
            ) : (
              <a
                href={`${process.env.KEY_SITE_ENDPOINT}/destinations/${dest.slug}${window.location.search}`}
                onClick={() => track('link_click', { cta: dest.name, section: 'Featured Destinations' }, analyticsContext)}
                target="_blank"
                rel="noopener"
              >
                <Card className="featured-destination-card">
                  <BackgroundDiv imageURL={dest.heroImage} className="hero-image" />
                  <div className="label">
                    <div className="destination-name">
                      {dest.name}
                    </div>
                    <div className="listings-count">
                      View {dest.numPublishedListings} Homes
                    </div>
                  </div>
                </Card>
              </a>
            )
          ))
        }
      </div>
      <a
        href={`${process.env.ITINERARY_ENDPOINT}/listings${window.location.search}`}
        onClick={() => track('link_click', { cta: 'View All Destinations', section: 'Featured Destinations' }, analyticsContext)}
        target="_blank"
        rel="noopener"
      >
        View all destinations
      </a>
    </section>
  );
};

FeaturedDestinationsSection.propTypes = {
  analyticsContext: PropTypes.object,
};

FeaturedDestinationsSection.defaultProps = {
  analyticsContext: {},
};

export default FeaturedDestinationsSection;
