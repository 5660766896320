import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  BackgroundDiv, Button, Card, Tabs, TabPanel, QueryModule,
} from '@keydotco/key-components';
import InlineSVG from 'svg-inline-react';
import home from '../../../assets/icons/home2-filled.svg';
import diamond from '../../../assets/icons/diamond.svg';
import { track, trackLink } from '../../../services/analytics';
import ServicesQueryModule from './ServicesQueryModule';

import './VacationHomeIntro.css';

const VacationHomeIntro = ({ destinations, markets, analyticsContext }) => {
  const listHomeLinkRef = useRef();
  const experiencesLinkRef = useRef();
  const [mobileMode, setMobileMode] = useState(window.innerWidth <= 850);

  useEffect(() => {
    const onResize = () => setMobileMode(window.innerWidth <= 850);
    window.addEventListener('resize', onResize);
    onResize();
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useEffect(() => {
    if (listHomeLinkRef.current) {
      trackLink(listHomeLinkRef.current, 'link_click', {
        cta: 'List Your Home',
        section: 'Partner Hero Card',
        ...analyticsContext,
      });
    }

    if (experiencesLinkRef.current) {
      trackLink(experiencesLinkRef.current, 'link_click', {
        cta: 'Become a Travel Partner',
        section: 'Partner Hero Card',
        ...analyticsContext,
      });
    }
  }, [listHomeLinkRef, experiencesLinkRef]);

  const handleOnSearch = () => {
    track('link_click', {
      cta: 'Search Homes', section: 'Traveler Hero Card', sub_section: 'Homes Tab',
    }, analyticsContext);
  };

  const HomesTabLabel = () => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{ width: '100%' }}
      onClick={() => track('link_click', {
        cta: 'Homes', section: 'Traveler Hero Card', sub_section: 'Offering Tabs',
      }, analyticsContext)}
    >
      <InlineSVG src={home} className="home-icon" />
      Homes
    </div>
  );

  const ExperiencesTabLabel = () => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      style={{ width: '100%' }}
      onClick={() => track('link_click', {
        cta: 'Experiences', section: 'Traveler Hero Card', sub_section: 'Offering Tabs',
      }, analyticsContext)}
    >
      <InlineSVG src={diamond} className="experiences-icon" />
      Experiences
    </div>
  );

  const onFullPageOpen = () => {
    if (window.innerWidth > 600) {
      return;
    }
    const navElem = document.getElementsByTagName('nav')[0];
    navElem.style.display = 'none';
  };

  const onFullPageClose = () => {
    if (window.innerWidth > 600) {
      return;
    }
    const navElem = document.getElementsByTagName('nav')[0];
    navElem.style.display = 'flex';
  };

  const dateFormat = 'M j';

  return (
    <BackgroundDiv
      imageURL="https://static.key.co/img/home/house-exterior-with-pool-1.webp"
      className="vacation-homes-intro"
    >
      <div className="container">
        <Card className="guest-card">
          <h1>
            Book an unforgettable getaway
          </h1>
          <Tabs>
            <TabPanel key={1} label={HomesTabLabel()}>
              <QueryModule
                listingCatalogUrl={`${process.env.ITINERARY_ENDPOINT}/listings`}
                onSearch={handleOnSearch}
                marginTop={mobileMode ? 63 : 73}
                markets={destinations.map((dest) => ({
                  name: dest.name ?? dest,
                  value: dest.name ?? dest,
                  tags: dest.aliases,
                }))}
                onFullPageOpen={onFullPageOpen}
                onFullPageClose={onFullPageClose}
                additionalUrlParams={[{
                  label: 'keysite_content',
                  value: 'explore_homes',
                }]}
                isSingleButton
                dateFormat={dateFormat}
              />
            </TabPanel>
            <TabPanel key={2} label={ExperiencesTabLabel()}>
              <ServicesQueryModule
                markets={markets}
                onFullPageOpen={onFullPageOpen}
                onFullPageClose={onFullPageClose}
                dateFormat={dateFormat}
                analyticsContext={analyticsContext}
              />
            </TabPanel>
          </Tabs>
        </Card>
        <Card className="pm-card">
          <h2>
            Partner with KEY today
          </h2>
          <div className="button-row">
            <a
              ref={listHomeLinkRef}
              href="https://corp.key.co/home-listings/"
            >
              <Button>List Your Home</Button>
            </a>
            <a
              ref={experiencesLinkRef}
              href={`${process.env.CORPORATE_SITE_ENDPOINT}/travel-partners/`}
              rel="noreferrer"
              target="_blank"
            >
              <Button variant="secondary">Become a Travel Partner</Button>
            </a>
          </div>
        </Card>
      </div>
    </BackgroundDiv>
  );
};

VacationHomeIntro.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.string),
  markets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  analyticsContext: PropTypes.object,
};

VacationHomeIntro.defaultProps = {
  destinations: [],
  markets: [],
  analyticsContext: {},
};

export default VacationHomeIntro;
