import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundDiv } from '@keydotco/key-components';

import InfoSectionStepCard from '../InfoSectionStepCard';
import { track } from '../../services/analytics';

import './styles.css';

const InHomeServicesSection = ({ isStep2, showVacationHomes, analyticsContext }) => {
  const groceryImage = 'https://static.key.co/img/home/grocery-delivery.jpg';
  const babySittingImage = 'https://static.key.co/img/home/babysitting.jpg';
  const privateChefImage = 'https://static.key.co/img/home/private-chef.jpg';
  const massageImage = 'https://static.key.co/img/home/massage.jpg';
  const mealsImage = 'https://static.key.co/img/home/meals.jpg';
  const cateringDeliveredImage = 'https://static.key.co/img/home/catering-delivered.jpg';

  const trackClick = (name) => track('link_click', {
    cta: name, section: 'In-home Services', ...analyticsContext,
  });

  const Collage = () => (
    <div className="experience-card-container">
      <BackgroundDiv
        imageURL={cateringDeliveredImage}
        className="experience-card"
        screenPercentage={0.5}
        onClick={() => trackClick('Catering Delivered')}
        withGradient
      >
        <p>Catering Delivered</p>
      </BackgroundDiv>
      <div className="experience-card-column">
        <BackgroundDiv
          imageURL={privateChefImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('Private Chef')}
          withGradient
        >
          <p>Private Chef</p>
        </BackgroundDiv>
        <BackgroundDiv
          imageURL={mealsImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('Farm to Table Meals')}
          withGradient
        >
          <p>Farm to Table Meals</p>
        </BackgroundDiv>
      </div>
      <div className="experience-card-column">
        <BackgroundDiv
          imageURL={babySittingImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('Babysitter')}
          withGradient
        >
          <p>Babysitter</p>
        </BackgroundDiv>
        <BackgroundDiv
          imageURL={massageImage}
          className="experience-card"
          screenPercentage={0.5}
          onClick={() => trackClick('In-home Massage')}
          withGradient
        >
          <p>In-home Massage</p>
        </BackgroundDiv>
      </div>
    </div>
  );

  return (
    <div className="in-home-services-wrapper">
      <div className="homepage-section in-home-services-section container">
        <div className="info-step-container">
          <InfoSectionStepCard
            stepNumber={isStep2 ? '2' : undefined}
            header={
              !showVacationHomes
                ? 'Enjoy a stocked fridge upon arrival. Indulge in a dinner prepared by a private chef. Reach peak relaxation with an in-home massage.'
                : 'Stock the fridge before you arrive. Relax with an in-home massage. Indulge in a dinner prepared by a private chef.'
            }
            stepTitle={isStep2 ? 'Add In-home Services' : 'In-Home Services'}
            subtext={
              !showVacationHomes
                ? 'KEY delivers the unforgettable to each home.'
                : 'When you book a home with KEY, we can help you elevate your stay from start to finish.'
            }
            images={[
              { url: groceryImage, title: 'Pre-arrival Grocery' },
              { url: privateChefImage, title: 'Private Chef' },
              { url: babySittingImage, title: 'Babysitter' },
              { url: mealsImage, title: 'Farm to Table Meals' },
              { url: massageImage, title: 'In-home Massage' },
            ]}
            collage={<Collage />}
          />
        </div>
        <Collage />
      </div>
    </div>
  );
};

InHomeServicesSection.propTypes = {
  isStep2: PropTypes.bool,
  showVacationHomes: PropTypes.bool,
  analyticsContext: PropTypes.object,
};

InHomeServicesSection.defaultProps = {
  isStep2: false,
  showVacationHomes: false,
  analyticsContext: {},
};

export default InHomeServicesSection;
