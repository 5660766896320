import Parse from 'parse';

const request = require('superagent');

Parse.initialize(process.env.PARSE_APP_ID, process.env.PARSE_JSKEY, process.env.PARSE_MASTERKEY);
Parse.serverURL = process.env.PARSE_SERVER;

const common = async (httpRequest, data) => {
    const currentUser = Parse.User.current();
    httpRequest.set('X-Parse-SessionToken', currentUser ? currentUser.get('sessionToken') : '')
        .set('Content-Type', 'application/json')
        .send(data);
    return httpRequest;
};

/**
 * REST Client
 * @param {*} httpMethod - GET, POST, PUT, DELETE
 * @param {*} pathEndpoint - URL to the REST resource, ex. https://api.keyapp.io/api/questionnaire/SDF23AfA
 * @param {*} data - Data object to be converted to JSON
 */
export const get = async (pathEndpoint) => {
    const path = `${process.env.REST_ENDPOINT}${pathEndpoint}`;
    const httpRequest = request.get(path);
    return common(httpRequest, null);
};

export const post = async (pathEndpoint, data) => {
    const path = `${process.env.REST_ENDPOINT}${pathEndpoint}`;
    const httpRequest = request.post(path);
    return common(httpRequest, data);
};

export const put = async (pathEndpoint, data) => {
    const path = `${process.env.REST_ENDPOINT}${pathEndpoint}`;
    const httpRequest = request.put(path);
    return common(httpRequest, data);
};

export const del = async (pathEndpoint, data) => {
    const path = `${process.env.REST_ENDPOINT}${pathEndpoint}`;
    const httpRequest = request.del(path);
    return common(httpRequest, data);
};


export default {
    get, put, post, del,
};
