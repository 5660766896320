import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from '../../scenes/App';
import routes from '../../routes';

export const scrollToTop = () => window.scrollTo(0, 0);

const Root = ({ store }) => (
  <Provider store={store}>
    <Router onUpdate={scrollToTop}>
      <App>
        {routes}
      </App>
    </Router>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
