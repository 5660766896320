import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './rootReducer';

const middlewares = [thunkMiddleware];

if (process.env.APP_ENV !== 'production') {
  const loggerMiddleware = createLogger({ collapsed: true, diff: true });
  middlewares.push(loggerMiddleware);
}

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(...middlewares),
  );
}
