import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import URLSearchParams from 'url-search-params';
import { Button, ColumnList } from '@keydotco/key-components';
import GuaranteeStamp from '../../assets/img/guarantee-stamp.svg';
import { track } from '../../services/analytics';
import './styles.css';

const DestinationsSection = ({
  destinations, isExploreHomes, partner, analyticsContext,
}) => {
  const goToTop = () => {
    track('link_click', { cta: 'Start Exploring', section: 'Destination Footer' }, analyticsContext);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const handleExplore = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('keysite_content', 'explore_homes');
    if (partner) { urlParams.set('partner', partner); }
    track('link_click', { cta: 'Start Exploring', section: 'Destination Footer' }, analyticsContext);
    window.location = `${process.env.ITINERARY_ENDPOINT}/listings?${urlParams.toString()}`;
  };

  const trackDestinationClick = (dest) => {
    track('link_click', {
      cta: dest.name,
      section: 'Destination Footer',
      targetDestinationSlug: dest.slug,
    }, analyticsContext);
  };

  const destinationKeys = destinations.map((dest) => dest.name);
  const destinationLinks = destinations.map((dest) => (
    <a
      href={`${process.env.KEY_SITE_ENDPOINT}/destinations/${dest.slug}${window.location.search}`}
      rel="noopener noreferrer"
      target="_blank"
      onClick={() => trackDestinationClick(dest)}
    >
      {dest.name}
    </a>
  ));

  return (
    <div className="homepage-section markets-section">
      <InlineSVG src={GuaranteeStamp} className="guarantee-stamp" />
      <div className="marketing-question">
        Ready to make life effortless and moments unforgettable?
      </div>
      <Button
        variant="primary"
        onClick={isExploreHomes ? handleExplore : goToTop}
      >
        Start Exploring
      </Button>
      <div className="markets">
        <ColumnList
          title="Our Destinations"
          keys={destinationKeys}
          list={destinationLinks}
        />
      </div>
    </div>
  );
};

DestinationsSection.propTypes = {
  destinations: PropTypes.arrayOf(PropTypes.any).isRequired,
  isExploreHomes: PropTypes.bool,
  partner: PropTypes.string,
  analyticsContext: PropTypes.object,
};

DestinationsSection.defaultProps = {
  isExploreHomes: true,
  partner: null,
  analyticsContext: {},
};

export default DestinationsSection;
