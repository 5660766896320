import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import URLSearchParamsPolyfill from 'url-search-params';
import { loadStripe } from '@stripe/stripe-js';
import { ElementsConsumer, Elements } from '@stripe/react-stripe-js';

import Completed from './Completed';
import TokenUsed from './TokenUsed';
import TokenInvalid from './TokenInvalid';
import TokenExpired from './TokenExpired';
import { track } from '../../../services/analytics';
import Loading from '../../Loading/Loading';
import withBilling from '../../../HOC/BillingHOC';

import CreditCardForm from '../CreditCardForm';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY);

class AdditionalCardContainer extends Component {
  static propTypes = {
    storeCardDetails: PropTypes.func.isRequired,
    getTokenStatus: PropTypes.func.isRequired,
  };

  state = {
    completed: false,
    tokenStatus: '',
    tokenValid: true,
    loading: false,
    submitting: false,
    token: '',
  };

  UNSAFE_componentWillMount() {
    const token = new URLSearchParamsPolyfill(window.location.search).get('token');

    if (token) {
      this.setState({ loading: true });
    } else {
      this.setState({ loading: false, tokenValid: false });
    }
  }

  componentDidMount() {
    const token = new URLSearchParamsPolyfill(window.location.search).get('token');

    if (token) {
      this.props.getTokenStatus(token)
        .then((tokenObject) => {
          const tokenStatus = tokenObject.status;
          this.setState({ tokenStatus, loading: false });
          if (tokenStatus === 'expired' || tokenStatus === 'used' || isEqual(tokenStatus, {})) {
            this.setState({ tokenValid: false });
          } else {
            this.setState({ token });
          }
          return tokenStatus;
        })
        .catch(() => this.setState({ loading: false, tokenValid: false }));
    }
  }

  addCreditCard = (stripeToken) => {
    track('Add Additional Credit Card');

    this.setState({ submitting: true });
    this.props.storeCardDetails(this.state.token, stripeToken)
      .then(() => this.setState({ completed: true, submitting: false }))
      .catch(() => this.setState({ submitting: false }));
  };

  render() {
    if (this.state.loading || this.state.submitting) {
      return <Loading fullScreen />;
    }
    if (this.state.tokenStatus === 'expired') {
      return <TokenExpired />;
    }
    if (this.state.tokenStatus === 'used') {
      return <TokenUsed />;
    }
    if (!this.state.tokenValid) {
      return <TokenInvalid />;
    }
    if (this.state.completed) {
      return <Completed />;
    }

    return (
      <div className="key-ccform-holder">
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <CreditCardForm
                addNewCardToItinerary={this.addCreditCard}
                stripe={stripe}
                elements={elements}
              />
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    );
  }
}

export default withBilling(AdditionalCardContainer);
