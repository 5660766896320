import React, { Component } from 'react';
import { BackgroundDiv } from '@keydotco/key-components';

const withIntroWrapper = (className) => (WrappedComponent) => {
  class IntroWrapper extends Component {
    state = {
      isMouseDown: false,
      showRequiredState: null,
    };

    setRequiredState = (requiredField) => this.setState({ showRequiredState: requiredField });

    setMouseDown = () => this.setState({ isMouseDown: true });

    setMouseUp = () => this.setState({ isMouseDown: false });

    getBackgroundImageUrl = () => {
      if (className === 'intro-panel-vacation-homes') {
        return 'https://static.key.co/img/home/cropped-luggage-2.jpg';
      }

      return 'https://static.key.co/img/home/backyard-dinner.jpeg';
    };

    render() {
      return (
        <BackgroundDiv
          imageURL={this.getBackgroundImageUrl()}
          className={`questionnaire-panel ${className} ${
            this.state.isMouseDown ? 'mouse-down' : ''
          }`}
        >
          <div className="container no-max">
            <div className="gold-border-box">
              <WrappedComponent
                {...this.props}
                setMouseDown={this.setMouseDown}
                setMouseUp={this.setMouseUp}
                setRequiredState={this.setRequiredState}
                showRequiredState={this.state.showRequiredState}
              />
            </div>
          </div>
        </BackgroundDiv>
      );
    }
  }

  return IntroWrapper;
};

export default withIntroWrapper;
