import {
  GET_PARTNER, GET_PARTNER_DONE, RECEIVE_PARTNER, SET_PARTNER_ERROR,
} from './actions';

function partner(state = {
  loading: true,
  partnerPage: false,
  logo: '',
  homepageLink: '',
  error: null,
}, action) {
  switch (action.type) {
    case GET_PARTNER:
      return { ...state, loading: true };
    case GET_PARTNER_DONE:
      return { ...state, loading: false };
    case SET_PARTNER_ERROR:
      return { ...state, error: action.error };
    case RECEIVE_PARTNER:
      return {
        ...state,
        ...action.partnerInfo.body,
        loading: false,
        partnerPage: Boolean(action.partnerInfo.body.logo),
      };
    default:
      return state;
  }
}

export default partner;
