import '@babel/polyfill';
import 'intersection-observer';
import objectFitImages from 'object-fit-images';

// Packages
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import 'normalize.css';

import Root from './components/Root';
import configureStore from './data/configureStore';
import { setupAnalytics } from './services/analytics';
import sso from './sso';

import './assets/icons.css';
import './main.css';

Sentry.init({
  dsn: 'https://61153538e5e9aa941ca9bec94bd9f1a7@o385560.ingest.us.sentry.io/4508133229133824',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ['localhost'],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  environment: window.location.hostname === 'local-key.co' ? 'local' : process.env.APP_ENV,
});

setupAnalytics();
sso.cleanUpExpiredSession();

objectFitImages();

const store = configureStore();

ReactDOM.render(
  <Root store={store} />,
  document.getElementById('root'),
);
