import React from 'react';

export default function TokenExpired() {
  return (
    <div className="additional-card key-card-container">
      <div className="key-card is-centered">
        <div>
          <h2>Token Expired</h2>
          <p>This credit card token has expired. Please contact us.</p>
        </div>
      </div>
    </div>
  );
}
