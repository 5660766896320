import { SET_SOURCE_ITINERARY, LOADING_SOURCE_ITINERARY } from './actions';

export default function sourceItinerary(state = { isLoading: false, source: {} }, action) {
  switch (action.type) {
    case LOADING_SOURCE_ITINERARY:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_SOURCE_ITINERARY:
      return {
        ...state,
        source: action.payload,
      };
    default:
      return state;
  }
}
