import { combineReducers } from 'redux';

// Reducers
import { reducer as notifications } from 'react-notification-system-redux';
import activeItinerary from '../activeItinerary/reducer';
import partner from '../partner/reducer';
import user from '../user/reducer';
import showSignIn from '../showSignIn/reducer';
import lead from '../lead/reducer';
import countries from '../resources/reducer';
import questionnaire from '../questionnaire/reducer';
import markets from '../markets/reducer';
import destinations from '../destinations/reducer';
import sourceItinerary from '../sourceItinerary/reducer';
import analytics from '../analytics/reducer';

export default combineReducers({
  notifications,
  activeItinerary,
  analytics,
  partner,
  user,
  showSignIn,
  lead,
  countries,
  questionnaire,
  markets,
  destinations,
  sourceItinerary,
});
