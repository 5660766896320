import restClient from '../../services/util/http';

export const SET_DESTINATIONS = 'SET_DESTINATIONS';
export const LOADING_DESTINATIONS = 'LOADING_DESTINATIONS';
export const LOADED_DESTINATIONS = 'LOADED_DESTINATIONS';

const loadingDestinations = () => ({
  type: LOADING_DESTINATIONS,
});
const loadedDestinations = () => ({
  type: LOADED_DESTINATIONS,
});

const setDestinations = (destinations) => ({
  type: SET_DESTINATIONS,
  payload: destinations,
});

export const fetchDestinations = () => (dispatch) => {
  dispatch(loadingDestinations());
  return restClient.get('/api/listings/destinations')
    .then((res) => {
      dispatch(setDestinations(res.body));
      return dispatch(loadedDestinations());
    })
    .catch((err) => {
      console.log(err); // eslint-disable-line no-console
      return dispatch(loadedDestinations());
    });
};
