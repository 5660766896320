import React from 'react';
import PropTypes from 'prop-types';
import VettedHomesSection from '../VettedHomesSection';
import LocalExperiencesSection from '../LocalExperiencesSection';
import InHomeServicesSection from '../InHomeServicesSection';

import './styles.css';

const StepsSection = ({
  showVacationHomes,
  partner,
  analyticsContext,
}) => {
  const showStepNumber = showVacationHomes;
  return (
    <div className="steps-sections-wrapper">
      {showVacationHomes && (
        <VettedHomesSection
          partner={partner}
          analyticsContext={analyticsContext}
        />
      )}
      <InHomeServicesSection
        isStep2={showStepNumber}
        showVacationHomes={showVacationHomes}
        analyticsContext={analyticsContext}
      />
      <div className="local-experiences-wrapper">
        <LocalExperiencesSection
          isStep3={showStepNumber}
          screenWidth={600}
          analyticsContext={analyticsContext}
        />
      </div>
    </div>
  );
};

StepsSection.propTypes = {
  showVacationHomes: PropTypes.bool.isRequired,
  partner: PropTypes.string,
  analyticsContext: PropTypes.object,
};

StepsSection.defaultProps = {
  partner: null,
  analyticsContext: {},
};

export default StepsSection;
