import React from 'react';
import stripeLogo from '../../../assets/img/powered_by_stripe.png';

const StripeLogo = () => (
  <a
    href="https://stripe.com/"
    target="_blank"
    rel="noreferrer noopener"
    style={{ marginTop: '1rem', marginRight: 'auto' }}
  >
    <img src={stripeLogo} alt="Powered by Stripe" />
  </a>
);

export default StripeLogo;
