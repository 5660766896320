import assert from 'assert';
import session from './session';

/**
 * @module Factory that returns a configured utility with SSO helper functions to integrate an app with the SSO flow.
 */
export default function ({ ssoSessionCookieName, ssoLoginHostname }) {
  assert(ssoSessionCookieName, 'ssoSessionCookieName required');
  assert(ssoLoginHostname, 'ssoLoginHostname required');

  /**
   * Call this method during app bootstrapping. This helper clears any existing Parse user session
   * in local storage when a shared session cookie is not present.
   * Otherwise, Parse calls may try to use stale session ids that it finds in the local storage.
   */
  const cleanUpExpiredSession = () => {
    // Use a try-catch to avoid breaking the app during bootstrapping (in worst-case scenario).
    try {
      session.cleanUpExpiredSession(ssoSessionCookieName);
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Function to return the user from browser cookies but not forcibly redirect
   *
   * @param role (optional) The role that is required to access the app.
   * @returns {*} The user value stored in the browser cookie.
   */
  const getUser = ({ role }) => session.validateSessionAndRoles(role, ssoSessionCookieName);

  /**
   * Tries to restore user session from shared Key session cookie. If the
   * session is not valid, or if the user does not have the required role,
   * or if the authentication fails, the user is redirected to the
   * Key login portal. Upon successful authentication, the user will be
   * redirected back to the given url or the current url if no url is provided.
   *
   * @param role (optional) The role that is required to access this app.
   * @param url (optional) The url to return back to if it is different from
   *        the current window's location.
   * @param additionalParams (optional) Optional additional query params that may need to be passed to the login app
   *        (i.e. itinerary=<itinerary_id>)
   */
  const login = ({ role, url, additionalParams }) => (
    session.validateSessionAndRoles(role, ssoSessionCookieName)
      .catch(() => {
        let params = `?url=${encodeURIComponent(url || window.location.href)}`;
        if (additionalParams) {
          params += `&${additionalParams}`;
        }
        // Redirect to auth portal.
        window.location = `${ssoLoginHostname}/${params}`;
    })
  );

    /**
     * Tries to restore user session from shared Key session cookie. If the
     * session is not valid, or if the user does not have the required role,
     * or if the authentication fails, the user is redirected to the
     * Key login portal sign up page. Upon successful authentication or sign up, the user will be
     * redirected back to the given url or the current url if no url is provided.
     *
     * @param role (optional) The role that is required to access this app.
     * @param url (optional) The url to return back to if it is different from
     *        the current window's location.
     * @param additionalParams (optional) Optional additional query params that may need to be passed to the login app
     *        (i.e. itinerary=<itinerary_id>)
     */
  const signup = ({ role, url, additionalParams }) => (
      session.validateSessionAndRoles(role, ssoSessionCookieName)
          .catch(() => {
              let params = `?url=${encodeURIComponent(url || window.location.href)}`;
              if (additionalParams) {
                  params += `&${additionalParams}`;
              }
              // Redirect to auth portal.
              window.location = `${ssoLoginHostname}/sign-up/${params}`;
          })
  );

  /**
   * Logs user out without redirecting to the SSO login portal.
   */
  const logout = () => {
    session
      .logout(ssoSessionCookieName)
      .then(() => {
        // Force page reload.
        window.location.reload();
        return Promise.resolve();
      });
  };

  /**
   * Redirects user to logout portal to sign user out. The user is then taken to
   * the login portal, where they have the opportunity to log back in to the
   * given url or the current app url if no url is provided.
   *
   * @param url (optional) The url to return back to if it is different from
   *        the current window's location.
   */
  const logoutToSSOPortal = ({ url }) => {
    session
      .logout(ssoSessionCookieName)
      .then(() => {
        // Redirect to auth portal.
        window.location = `${ssoLoginHostname}/logout?url=${encodeURIComponent(url || window.location.href)}`;
        return Promise.resolve();
      });
  };

  return ({
    cleanUpExpiredSession,
    getUser,
    login,
    signup,
    logout,
    logoutToSSOPortal,
  });
};
