import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import { TextField } from '@keydotco/key-components';

import './NameEmailPanel.css';
import { validateEmail, validatePhone } from '../../../services/util';
import ArrowForward from '../../../assets/icons/left-arrow.svg';
import { track } from '../../../services/analytics';
import Loading from '../../Loading/Loading';
import RequiredSpeechBubble from '../../RequiredSpeechBubble/RequiredSpeechBubble';

export default class NameEmailPanel extends Component {
  static propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    isSubmitted: PropTypes.bool,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    analyticsContext: PropTypes.shape({
      page: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      step_index: PropTypes.number.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    isSubmitted: false,
  };

  state = {
    isMouseDown: false,
    emailError: false,
    showRequiredState: null,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.phone) {
      this.setState({ phoneError: !validatePhone(nextProps.phone) });
    }
    if (this.props.email) {
      this.setState({ emailError: !validateEmail(nextProps.email) });
    }
  }

  currentInputControl = () => {
    if (!this.props.firstName) {
      return 'first-name';
    }

    if (!this.props.lastName) {
      return 'last-name';
    }

    if (!validateEmail(this.props.email)) {
      return 'email';
    }

    if (this.props.phone && !validatePhone(this.props.phone)) {
      return 'phone';
    }

    return null;
  };

  handleNextClick = () => {
    track('link_click', { cta: 'next' }, this.props.analyticsContext);
    const currentInput = this.currentInputControl();
    if (currentInput != null) {
      this.setState({ showRequiredState: currentInput });
      return;
    }
    track('step_completed', this.props.analyticsContext);

    if (process.env.APP_ENV === 'production') {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Complete Questionnaire Step 3']);
      window.pagesense.push(['tagRecording', 'Complete Questionnaire Step 3']);
    }

    this.props.onNext();
  };

  handlePropertyChanged = (updatedProperty) => {
    this.setState({ showRequiredState: null });
    this.props.onChange(updatedProperty);
  };

  isDisabled = () => this.props.isSubmitted;

  render() {
    if (this.props.isSubmitted) {
      return <Loading fullScreen />;
    }
    return (
      <div className={`questionnaire-panel name-email-panel ${this.state.isMouseDown ? 'mouse-down' : ''}`}>
        <div className="panel-content">
          <div className="current-page">
            <strong>Step {this.props.step + 1}</strong> of 3
          </div>
          <h2 className="header-title">You&apos;re almost done</h2>
          <div className="header-text">Your details are needed to save your recommendations and create an account.</div>
          <div className="input-prompts-row">
            <span className="input-group">
              <div className="input-row">
                <div className="input-container">
                  <TextField
                    name="First name"
                    spellCheck="false"
                    autoCorrect="off"
                    type="text"
                    className="first-name"
                    disabled={this.props.loggedIn}
                    value={this.props.firstName}
                    placeholder="First name"
                    onChange={
                      (changeValue, displayValue) => this.handlePropertyChanged({
                        firstName: displayValue,
                      })
                    }
                  />
                  <RequiredSpeechBubble
                    text="Please add your first name"
                    isVisible={this.state.showRequiredState === 'first-name'}
                  />
                </div>
                <div className="input-container">
                  <TextField
                    name="Last name"
                    spellCheck="false"
                    autoCorrect="off"
                    type="text"
                    className="last-name"
                    disabled={this.props.loggedIn}
                    value={this.props.lastName}
                    placeholder="Last name"
                    onChange={
                      (changeValue, displayValue) => this.handlePropertyChanged({
                        lastName: displayValue,
                      })
                    }
                  />
                  <RequiredSpeechBubble
                    text="Please add your last name"
                    isVisible={this.state.showRequiredState === 'last-name'}
                  />
                </div>
              </div>
            </span>
            <span className="input-group">
              <div className="input-row" style={{ flexFlow: 'column' }}>
                <TextField
                  name="Email address"
                  spellCheck="false"
                  autoCorrect="off"
                  autoCapitalize="off"
                  type="text"
                  className="email"
                  disabled={this.props.loggedIn}
                  value={this.props.email}
                  placeholder="Email"
                  onChange={
                    (changeValue, displayValue) => this.handlePropertyChanged({
                      email: displayValue,
                    })
                  }
                />
                <RequiredSpeechBubble
                  text="Please add your email address"
                  isVisible={this.state.showRequiredState === 'email' && !this.state.emailError}
                />
                {this.state.emailError && (
                  <div className="name-email-spacer">
                    <div className="error-text">Email is invalid</div>
                  </div>
                )}
              </div>
              <div className="input-row" style={{ flexFlow: 'column' }}>
                <TextField
                  name="Phone number (Optional)"
                  type="tel"
                  value={this.props.phone}
                  className="phone"
                  placeholder="Phone (Optional)"
                  onChange={
                    (changeValue, displayValue) => this.handlePropertyChanged({
                      phone: displayValue,
                    })
                  }
                />
                {this.props.phone && this.state.phoneError && (
                  <div className="name-email-spacer">
                    <div className="error-text">Phone is invalid</div>
                  </div>
                )}
              </div>
            </span>
          </div>

          <div className="panel-footer">
            <button
              type="button"
              className="back-button"
              onClick={this.props.onBack}
            >
              <InlineSVG src={ArrowForward} />
              BACK
            </button>
            <button
              type="button"
              className="next-button"
              onClick={this.handleNextClick}
              disabled={this.isDisabled()}
              onMouseDown={() => this.setState({ isMouseDown: true })}
              onMouseUp={() => this.setState({ isMouseDown: false })}
              onTouchStart={() => this.setState({ isMouseDown: true })}
              onTouchEnd={() => this.setState({ isMouseDown: false })}
            >
              SEE RECOMMENDATIONS
            </button>
          </div>
        </div>
      </div>
    );
  }
}
