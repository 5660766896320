export const SET_STEP_COUNT = 'SET_STEP_COUNT';
export const NEXT_STEP = 'NEXT_STEP';
export const PREV_STEP = 'PREV_STEP';

export const setStepCount = (count) => ({
  type: SET_STEP_COUNT,
  payload: count,
});

export const nextStep = () => ({
  type: NEXT_STEP,
});

export const prevStep = () => ({
  type: PREV_STEP,
});
