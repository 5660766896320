import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DateRangeField } from '@keydotco/key-components';
import InlineSVG from 'svg-inline-react';
import moment from 'moment-timezone';
import { AutoComplete, Input } from 'antd';
import URLSearchParams from 'url-search-params';
import { track } from '../../../../services/analytics';
import marker from '../../../../assets/icons/marker.svg';
import clear from '../../../../assets/icons/clear.svg';
import downArrow from '../../../../assets/icons/down-arrow.svg';
import RequiredSpeechBubble from '../../../RequiredSpeechBubble/RequiredSpeechBubble';

const { Option } = AutoComplete;

const ServicesQueryModule = ({
  markets, onFullPageOpen, onFullPageClose, dateFormat, analyticsContext,
}) => {
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showNoDestinationError, setShowNoDestinationError] = useState(false);

  const setDates = (content) => {
    if (!content || content.length < 2) {
      setStartDate('');
      setEndDate('');
      return;
    }
    setStartDate(content[0]);
    setEndDate(content[1]);
  };

  const handleSubmit = (event) => {
    if (event) { event.preventDefault(); }
    if (!selectedMarket) {
      setShowNoDestinationError(true);
      return false;
    }
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('keysite_content', 'search_experiences');
    if (startDate && endDate) {
      urlParams.set('start_date', moment(startDate).format('MM-DD-YYYY'));
      urlParams.set('end_date', moment(endDate).format('MM-DD-YYYY'));
    }
    track('link_click', { cta: 'View Experiences', section: 'Traveler Hero Card', sub_section: 'Experiences Tab' }, analyticsContext);
    window.location = `${process.env.ITINERARY_ENDPOINT}/markets/${selectedMarket}/readonly-services?${urlParams.toString()}`;
    return true;
  };

  useEffect(() => {
    if (showNoDestinationError && selectedMarket) {
      setShowNoDestinationError(false);
    }
  }, [selectedMarket]);

  return (
    <form
      onSubmit={(event) => handleSubmit(event)}
      className="query-module-container experiences-query-module-container"
    >
      <div className="filters">
        <div className="inputs-container">
          <div>
            <AutoComplete
              className="destination-search"
              size="large"
              dataSource={
                markets.map((market) => (
                  <Option
                    key={market.id}
                    value={market.id}
                  >
                    {market.name}
                  </Option>
                ))
              }
              onSelect={(val) => setSelectedMarket(val)}
              value={selectedMarket}
              onSearch={(val) => setSelectedMarket(val)}
              dropdownClassName="destinations-drop-down"
            >
              <Input
                data-input
                placeholder="Destination"
                prefix={<InlineSVG className="destination-prefix-icon" src={marker} />}
                suffix={
                  selectedMarket
                    ? <InlineSVG src={clear} onClick={() => setSelectedMarket(null)} />
                    : <InlineSVG src={downArrow} />
                }
                data-hj-allow
                readOnly
              />
            </AutoComplete>
            <RequiredSpeechBubble
              text="Please select your destination"
              isVisible={showNoDestinationError}
            />
          </div>
          <DateRangeField
            placeholder="Enter Dates"
            startDate={startDate}
            endDate={endDate}
            monthsCount={1}
            hasPrefixIcon
            onChange={({ content }) => { setDates(content); }}
            timeZone={moment.tz.guess()}
            isFullPage
            isStatic
            dateFormat={dateFormat}
            onOpen={onFullPageOpen}
            onClose={onFullPageClose}
          />
        </div>
        <div className="query-form-btns">
          <Button
            className="search-btn"
            type="submit"
            size="large"
          >
            View Experiences
          </Button>
        </div>
      </div>
    </form>
  );
};

ServicesQueryModule.propTypes = {
  analyticsContext: PropTypes.object.isRequired,
  markets: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onFullPageOpen: PropTypes.func.isRequired,
  onFullPageClose: PropTypes.func.isRequired,
  dateFormat: PropTypes.string.isRequired,
};

ServicesQueryModule.defaultProps = {
  markets: [],
};

export default ServicesQueryModule;
