import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import FeaturedDestinationsSection from '../../../../components/FeaturedDestinationsSection';
import StepsSections from '../../../../components/StepsSections';
import GuestTestimonialSection from '../../../../components/GuestTestimonialSection';
import DestinationsSection from '../../../../components/DestinationsSection';
import PromotionsSection from '../../../../components/PromotionsSection';
import VacationHomeIntro from '../../../../components/QuestionnaireFlow/VacationHomeIntro';
import { getMarketId, setCurrentPage } from '../../../../data/analytics/actions';
import { pageEvent } from '../../../../services/analytics';

import '../styles.css';

class HomePage extends Component {
  static propTypes = {
    leadPartner: PropTypes.shape({
      isHomesCatalogPartner: PropTypes.bool,
      id: PropTypes.string,
    }),
    lead: PropTypes.shape({
      id: PropTypes.string,
    }),
    partner: PropTypes.shape({
      id: PropTypes.string,
    }),
    activeDestinations: PropTypes.arrayOf(),
    activeMarkets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    subdomain: PropTypes.string.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getMarketId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    leadPartner: {},
    lead: {},
    partner: {},
    activeDestinations: [],
    activeMarkets: [],
  };

  UNSAFE_componentWillMount() {
    if (process.env.APP_ENV === 'production'
      && document.referrer.indexOf('key.co') === -1
      && window.location.href.indexOf('key.co') > -1) {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['tagRecording', 'Landed on homes homepage']);
      if (this.props.subdomain !== 'key') {
        window.pagesense.push(['tagRecording', `Subdomain: ${this.props.subdomain}`]);
      }
    }
  }

  componentDidMount() {
    this.props.setCurrentPage('Homes Landing Page');
    pageEvent('Homes Landing Page', this.generateAnalyticsContext());
  }

  generateAnalyticsContext(page) {
    const { lead, leadPartner, partner } = this.props;
    return {
      page,
      path: window.location.pathname,
      hostname: window.location.hostname,
      leadId: lead?.id,
      partner_id: partner?.id || leadPartner?.id,
      market_id: this.props.getMarketId(),
    };
  }

  render() {
    const analyticsContext = this.generateAnalyticsContext('Homes Landing Page');

    return (
      <div className="home-page">
        <VacationHomeIntro
          destinations={this.props.activeDestinations}
          markets={this.props.activeMarkets}
          analyticsContext={analyticsContext}
        />
        <PromotionsSection />
        <FeaturedDestinationsSection analyticsContext={analyticsContext} />
        <StepsSections
          showVacationHomes
          analyticsContext={analyticsContext}
        />
        <GuestTestimonialSection analyticsContext={analyticsContext} />
        <DestinationsSection
          destinations={this.props.activeDestinations}
          analyticsContext={analyticsContext}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasLeadSet: state.lead.id,
  leadPartner: state.lead.partner,
  lead: state.lead,
  partner: state.partner,
  activeDestinations: state.destinations.destinations,
  activeMarkets: state.markets.markets.filter((market) => market.status === 'active'),
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (...args) => dispatch(setCurrentPage(...args)),
  getMarketId: () => dispatch(getMarketId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
