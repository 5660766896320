export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const setCurrentPage = (payload) => ({
  type: SET_CURRENT_PAGE,
  payload,
});

export const getMarketId = () => (dispatch, getState) => {
  const state = getState();
  const PMMarkets = state.markets?.markets?.filter((m) => m.isActiveForPM);
  if (state.lead?.market?.objectId) {
    return state.lead.market.objectId;
  }
  if (PMMarkets.length === 1 && PMMarkets[0].id) {
    return PMMarkets[0].id;
  }
  return null;
};
