import React from 'react';
import Reason from './Reason';
import Clock from '../../assets/icons/clock.svg';
import Home from '../../assets/icons/home.svg';
import Curation from '../../assets/icons/curation.svg';

import './whyKeySection.css';

const WhyKeySection = () => (
  <div className="why-key-section container">
    <h2>
      From the small details to the big moments, we&rsquo;re here to...
    </h2>
    <div className="reasons">
      <Reason
        icon={Clock}
        title="Save you time"
        subtitle="Plan your trip from start to finish in one convenient place."
      />
      <Reason
        icon={Home}
        title="Source you the best"
        subtitle="Cut through the noise by browsing our curated catalog of top homes, services, and experiences in every destination."
      />
      <Reason
        icon={Curation}
        title="Ensure your stay is flawless"
        subtitle={'We\u2019ve elevated thousands of trips across the country. Let us do the same for you.'}
      />
    </div>
  </div>
);

export default WhyKeySection;
