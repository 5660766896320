import React, { Component } from 'react';
import PropTypes from 'prop-types';
import URLSearchParams from 'url-search-params';
import { Route, Switch, Redirect } from 'react-router-dom';

// Scenes
import PageNotFound from './components/PageNotFound/PageNotFound';
import CardAddition from './scenes/App/CardAddition';
import HomePage from './scenes/App/HomePage';

/**
 * This "component" will access all route Params passed on the endpoint
 * and extract them into query params, it will then redirect to the base
 * url and place the query params on the base route.
 * Note: this was needed because using the redirect alone did not trigger
 * a full page refresh.
 */
class RedirectToQueryParameterizedRoute extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.object,
    }),
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    history: PropTypes.object.isRequired,
  };

  static defaultProps = {
    match: {
      params: null,
    },
  };

  UNSAFE_componentWillMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    Object.keys(this.props.match.params)
      .filter((key) => !!key)
      .forEach((key) => urlParams.set(key, this.props.match.params[key]));
    // browserHistory runs a setState, so this needs to be within a componentWillMount()
    this.props.history.replace(`/?${urlParams.toString()}`);
  }

  render() {
    return null;
  }
}

export default (
  <Switch>
    <Redirect from="/:url*(/+)" to={(props) => props.location.pathname.slice(0, -1)} />
    <Route exact path={['/', '/index.html']} component={HomePage} />
    <Route exact path="/homes" render={(props) => <HomePage {...props} />} />
    <Redirect
      from="/experience/:market/:occasion/:service"
      to="/market/:market/services/:service"
    />
    <Redirect from="/services/:market" to="/market/:market/services" />
    <Route path="/experience/:m/:o" component={RedirectToQueryParameterizedRoute} />
    <Route path="/experience/:m" component={RedirectToQueryParameterizedRoute} />
    <Route path="/market/:m" component={RedirectToQueryParameterizedRoute} />
    <Route path="/market/:m/services/:service" component={RedirectToQueryParameterizedRoute} />
    <Route path="/market/:m/services" component={RedirectToQueryParameterizedRoute} />
    <Route
      path="/proposal/:id"
      component={(props) => {
        window.location = `${process.env.ITINERARY_ENDPOINT}/itineraries/${props.params.id}?utm_source=concierge_email`;
      }}
    />
    <Route
      path="/itinerary/:id"
      component={(props) => {
        window.location = `${process.env.ITINERARY_ENDPOINT}/itineraries/${props.params.id}?utm_source=concierge_email`;
      }}
    />
    <Route
      path="/itinerary/payments/:id"
      component={(props) => {
        window.location = `${process.env.ITINERARY_ENDPOINT}/itineraries/${props.params.id}/payments`;
      }}
    />
    <Route
      path="/history"
      component={() => {
        window.location = `${process.env.ITINERARY_ENDPOINT}/itineraries`;
      }}
    />
    <Route path="/card-addition" component={CardAddition} />
    <Route path="/page-not-found" component={PageNotFound} />
    <Route path="*" component={PageNotFound} />
  </Switch>
);
