import {
  SET_STEP_COUNT,
  NEXT_STEP,
  PREV_STEP,
} from './actions';

export default function questionnaire(state = 0, action) {
  switch (action.type) {
    case SET_STEP_COUNT:
      return action.payload;
    case NEXT_STEP: {
      const newCount = state + 1;
      return newCount;
    }
    case PREV_STEP: {
      const newCount = state - 1;
      return newCount;
    }
    default:
      return state;
  }
}
