import React from 'react';
import PropTypes from 'prop-types';

import './RequiredSpeechBubble.css';

const RequiredSpeechBubble = ({ isVisible, text }) => (
  <div className="required-wrapper">
    <p
      className="required-value"
      style={isVisible ? {} : { visibility: 'hidden' }}
    >
      {text}
    </p>
  </div>
);

RequiredSpeechBubble.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default RequiredSpeechBubble;
