import URLSearchParams from 'url-search-params';
import ssoFactory from './lib/single-sign-on';

const sso = ssoFactory({
  ssoSessionCookieName: process.env.SSO_COOKIE_NAME,
  ssoLoginHostname: process.env.SSO_SITE_ENDPOINT,
});

const redirectToSSOPortalIfNotLoggedIn = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const additionalParamsArray = [];
  if (urlParams.has('lead')) {
    additionalParamsArray.push(`lead=${urlParams.get('lead')}`);
  }
  const domainParts = window.location.hostname.split('.');
  if (domainParts.length === 3) {
    additionalParamsArray.push(`partner=${domainParts[0]}`);
  }
  sso.login({ role: null, additionalParams: additionalParamsArray.join('&') });
};

export default {
  redirectToSSOPortalIfNotLoggedIn,
  ...sso,
};
