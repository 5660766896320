import assert from 'assert';
import Parse from 'parse';
import { readCookie, saveCookie } from './cookies';
import { get } from './http';

/**
 * @module Helper functions to manage Parse user sessions across subdomains.
 */

/**
 * Helper function to parse user session, check validity of the session as well as
 * the role required for authentication.
 *
 * @param role (optional) The role that is required to access this app.
 * @param ssoSessionCookieName
 */
const validateSessionAndRoles = (role, ssoSessionCookieName) => {
  assert(ssoSessionCookieName);
  const session = readCookie(ssoSessionCookieName);
  if (session) {
    return Parse.User
      .become(session)
      .then(async function(parseUserObject) {
        const user = parseUserObject.toJSON();
        let authorized = true;
        if (role) {
          try {
            await get(`/api/is-auth/${role}`);
          } catch (e) {
            authorized = false;
          }
        }
        return authorized ? user : Promise.reject('User does not have required role.');
      });
  }
  return Promise.reject();
};

const clearSessionCookie = ssoSessionCookieName => {
  assert(ssoSessionCookieName);
  // Reset SSO session cookie
  saveCookie({
    cookieName: ssoSessionCookieName,
    cookieValue: ''
  });
};

const cleanUpExpiredSession = ssoSessionCookieName => {
  assert(ssoSessionCookieName);
  const session = readCookie(ssoSessionCookieName);
  if (!session) {
    Object
      .keys(localStorage)
      .filter(key => key.indexOf('Parse/') === 0)
      .forEach(key => localStorage.removeItem(key));
  }
};

const logout = ssoSessionCookieName => {
  assert(ssoSessionCookieName);
  return Parse.User.logOut()
    .catch(console.error)
    .then(() => {
      clearSessionCookie(ssoSessionCookieName);
      cleanUpExpiredSession(ssoSessionCookieName);
      return Promise.resolve();
    });
};

export default {
  validateSessionAndRoles,
  logout,
  cleanUpExpiredSession,
};
