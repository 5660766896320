import React from 'react';
import PropTypes from 'prop-types';

import './loading.css';

// Use this component for all loading needs. You can pass in the following props
// 1. fullScreen = true: will render an overlay background for the full screen
// 2. parent = true: will render an overlay background only for the size of the
//    parent that's set to position: relative

function Loading(props) {
  const parentClass = props.parent ? 'parent-size-overlay' : '';
  return (
    <div className={`loading-component ${props.fullScreen ? 'full-screen-overlay' : parentClass}`}>
      <div className="loading-container">
        <div className="loading-icon" />
      </div>
    </div>
  );
}

Loading.defaultProps = {
  parent: false,
  fullScreen: false,
};

Loading.propTypes = {
  parent: PropTypes.bool,
  fullScreen: PropTypes.bool,
};

export default Loading;
