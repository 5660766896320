import Set from 'core-js-pure/features/set';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import { Button } from '@keydotco/key-components';

import './UserInterestPanel.css';
import GroupingButton from './UserInterestButton';
import ArrowForward from '../../../assets/icons/left-arrow.svg';
import { track } from '../../../services/analytics';

export default class UserInterestPanel extends Component {
  static propTypes = {
    groupings: PropTypes.object,
    groupingOptions: PropTypes.array.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    description: PropTypes.string,
    analyticsContext: PropTypes.shape({
      page: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      step_index: PropTypes.number.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    groupings: new Set(),
    description: '',
  };

  state = {
    isMouseDown: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleSelectionChange = ({ value, newSelectedState }) => {
    // Clone the selection set to avoid mutating the state.
    const newSelectionSet = new Set(this.props.groupings);
    if (newSelectedState) {
      newSelectionSet.add(value);
    } else {
      newSelectionSet.delete(value);
    }
    this.props.onChange({
      groupings: newSelectionSet,
    });
  };

  handlePropertyChanged = (updatedProperty) => {
    this.props.onChange(updatedProperty);
  };

  handleNextClick = () => {
    track('link_click', { cta: 'next' }, this.props.analyticsContext);
    track('step_completed', this.props.analyticsContext);

    if (process.env.APP_ENV === 'production') {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Complete Questionnaire Step 2']);
      window.pagesense.push(['tagRecording', 'Complete Questionnaire Step 2']);
    }

    this.props.onNext();
  };

  /**
   * Input validation is implicitly done by these checks.
   */

  render() {
    return (
      <div className={`questionnaire-panel user-interest-panel ${this.state.isMouseDown ? 'mouse-down' : ''}`}>
        <div className="panel-content">
          <div className="current-page">
            <strong>Step {this.props.step + 1}</strong> of 3
          </div>
          <h2 className="header-title">Tell us about your trip</h2>
          <div className="header-text">We&apos;ll tailor our recommendations of services and local experiences to you.</div>
          {this.props.groupingOptions.length > 0 && (
            <div id="interests-section" className="part-b show">
              <div className="interest-rows">
                {
                  [...this.props.groupingOptions].map((g) => (
                    <GroupingButton
                      key={g.value}
                      value={g.value}
                      caption={g.displayValue}
                      imageUrl={g.imageUrl}
                      onClick={this.handleSelectionChange}
                      selected={this.props.groupings.has(g.value)}
                    />
                  ))
                }
              </div>
            </div>
          )}
          <div className="part-b show">
            <div className="control-label">Do you have a special reason for your trip? Let us know the details. (optional)</div>
            <div className="input-prompts-row">
              <div className="input-column">
                <textarea
                  value={this.props.description}
                  placeholder="Celebrating an anniversary? A birthday? Team building?"
                  onChange={(event) => this.handlePropertyChanged({
                    description: event.target.value,
                  })}
                />
              </div>
            </div>
          </div>
          <div className="panel-footer">
            <button
              type="button"
              className="back-button"
              onClick={this.props.onBack}
            >
              <InlineSVG src={ArrowForward} className="back-arrow" />
              BACK
            </button>
            <Button
              type="button"
              className="next-button"
              onClick={this.handleNextClick}
              onMouseDown={() => this.setState({ isMouseDown: true })}
              onMouseUp={() => this.setState({ isMouseDown: false })}
              onTouchStart={() => this.setState({ isMouseDown: true })}
              onTouchEnd={() => this.setState({ isMouseDown: false })}
            >
              NEXT
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
