import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';

const Reason = ({
  icon, title, subtitle, iconClassName,
}) => (
  <div className="reason">
    <InlineSVG src={icon} className={iconClassName} />
    <h3>{title}</h3>
    <p>{subtitle}</p>
  </div>
);

Reason.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  iconClassName: PropTypes.string,
};

Reason.defaultProps = {
  iconClassName: '',
};

export default Reason;
