import { connect } from 'react-redux';
import NavBar from './NavBar';
import { logOut } from '../../data/user/actions';
import { openLogin } from '../../data/showSignIn/actions';

const mapStateToProps = (state) => ({
  loggedIn: !!state.user.loggedIn,
  userId: state.user.id,
  userFirstName: state.user.firstName,
  userLastName: state.user.lastName,
  showSignIn: state.showSignIn,
  stepAdvanced: state.questionnaire !== 0,
  analyticsContext: {
    leadId: state.lead?.partner?.id,
    partner_id: state.partner?.id,
    ...state.analytics,
  },
});

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  openLogin: () => dispatch(openLogin()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
