import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import URLSearchParams from 'url-search-params';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import QuestionnaireFlow from '../../../../components/QuestionnaireFlow';
import WhyKeySection from '../../../../components/WhyKeySection';
import StepsSections from '../../../../components/StepsSections';
import GuestTestimonialSection from '../../../../components/GuestTestimonialSection';
import NumbersSection from '../../../../components/NumbersSection';
import DestinationsSection from '../../../../components/DestinationsSection';
import { getMarketId, setCurrentPage } from '../../../../data/analytics/actions';
import { pageEvent } from '../../../../services/analytics';

import '../styles.css';

class HomePage extends Component {
  static propTypes = {
    showHomepageSections: PropTypes.bool,
    location: PropTypes.shape({
      search: PropTypes.string,
    }),
    lead: PropTypes.shape({
      id: PropTypes.string,
    }),
    leadPartner: PropTypes.shape({
      isHomesCatalogPartner: PropTypes.bool,
      id: PropTypes.string,
    }),
    partner: PropTypes.shape({
      id: PropTypes.string,
    }),
    markets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      key: PropTypes.string,
      name: PropTypes.string,
      status: PropTypes.string,
      isActiveForPM: PropTypes.bool,
      featuredServices: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        image: PropTypes.string,
        headline: PropTypes.string,
        serviceMap: PropTypes.string,
      })),
    })),
    activeDestinations: PropTypes.arrayOf(),
    subdomain: PropTypes.string.isRequired,
    setCurrentPage: PropTypes.func.isRequired,
    getMarketId: PropTypes.func.isRequired,
  };

  static defaultProps = {
    leadPartner: {},
    partner: {},
    lead: {},
    showHomepageSections: true,
    location: null,
    markets: [],
    activeDestinations: [],
  };

  UNSAFE_componentWillMount() {
    const { subdomain } = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const partnerParam = urlParams.get('partner');
    const pmIdentifier = subdomain && subdomain !== 'key' ? subdomain : partnerParam;

    if (process.env.APP_ENV === 'production'
      && document.referrer.indexOf('key.co') === -1
      && window.location.href.indexOf('key.co') > -1) {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['tagRecording', 'Landed on services homepage']);
      if (subdomain !== 'key') {
        window.pagesense.push(['tagRecording', `Subdomain: ${subdomain}`]);
      }
    }

    this.setState({
      subdomain,
      pmIdentifier,
    });
  }

  componentDidMount() {
    this.props.setCurrentPage('Services Landing Page');
    pageEvent('Services Landing Page', this.generateAnalyticsContext());
  }

  generateAnalyticsContext(page) {
    const { lead, partner, leadPartner } = this.props;
    return {
      page,
      path: window.location.pathname,
      hostname: window.location.hostname,
      leadId: lead?.id,
      partner_id: partner?.id || leadPartner?.id,
      market_id: this.props.getMarketId(),
    };
  }

  render() {
    const partner = this.state.pmIdentifier;
    const marketId = this.props.getMarketId();
    const marketName = this.props.markets.find((m) => m.id === marketId)?.name;
    const analyticsContext = this.generateAnalyticsContext('Services Landing Page');

    return (
      <div className="home-page">
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <QuestionnaireFlow
          markets={this.props.markets}
          subdomain={this.state.subdomain}
          prefillMarket={marketName}
          analyticsContext={{
            section: 'questionnaire',
            ...analyticsContext,
          }}
        />
        {this.props.showHomepageSections && (
          <div>
            <WhyKeySection />
            <StepsSections
              showVacationHomes={false}
              partner={partner}
              analyticsContext={analyticsContext}
            />
            <GuestTestimonialSection analyticsContext={analyticsContext} />
            <NumbersSection
              markets={this.props.markets.filter((market) => (market.status !== 'hidden'))}
            />
            <DestinationsSection
              destinations={this.props.activeDestinations}
              isExploreHomes={false}
              partner={partner}
              analyticsContext={analyticsContext}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showHomepageSections: state.questionnaire === 0,
  hasLeadSet: state.lead.id,
  leadPartner: state.lead.partner,
  lead: state.lead,
  partner: state.partner,
  markets: state.markets.markets,
  activeDestinations: state.destinations.destinations,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (...args) => dispatch(setCurrentPage(...args)),
  getMarketId: () => dispatch(getMarketId()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage));
