import { connect } from 'react-redux';
import Notifications from 'react-notification-system-redux';
import isEmpty from 'lodash/isEmpty';
import QuestionnaireView from './QuestionnaireView';
import { kebabize } from '../../services/util';

const mapStateToProps = (state) => {
  let startDate;
  let endDate;
  let marketName;
  if (state.lead && !isEmpty(state.lead)) {
    startDate = state.lead.startDate;
    endDate = state.lead.endDate;
    marketName = state.lead.marketName ? kebabize(state.lead.marketName) : null;
  } else if (state.sourceItinerary.source) {
    startDate = state.sourceItinerary.source.startDate;
    endDate = state.sourceItinerary.source.endDate;
    marketName = state.sourceItinerary.source.marketName
      ? kebabize(state.sourceItinerary.source.marketName)
      : null;
  }
  return {
    user: state.user,
    leadId: state.lead.id,
    leadPartner: state.lead.partner,
    productLine: state.lead.productLine,
    prefillStartDate: startDate,
    prefillEndDate: endDate,
    prefillMarket: marketName,
    prefillPropertyType: state.lead && state.lead.propertyType,
    partner: state.partner,
    loadingMarkets: state.markets.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  showError: (titleMessage, errorMessage) => {
    dispatch(Notifications.error({
      title: titleMessage,
      message: errorMessage,
    }));
  },
});

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  // invoke default merge order
  const props = { ...ownProps, ...stateProps, ...dispatchProps };
  // manually resolve the potential conflict
  props.prefillMarket = ownProps.prefillMarket || stateProps.prefillMarket;
  return props;
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(QuestionnaireView);
