import {
  SET_MARKETS,
  LOADING_MARKETS,
  LOADED_MARKETS,
} from './actions';

export default function setMarketNames(state = { markets: [], loading: true }, action) {
  switch (action.type) {
    case SET_MARKETS:
      return { ...state, markets: action.payload };
    case LOADING_MARKETS:
      return { ...state, loading: true };
    case LOADED_MARKETS:
      return { ...state, loading: false };
    default:
      return state;
  }
}
