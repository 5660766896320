import { connect } from 'react-redux';
import { storeCardDetails, getTokenStatus } from '../../../data/user/actions';

import AdditionalCardContainer from './AdditionalCardContainer';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  storeCardDetails: (token, stripeToken) => dispatch(storeCardDetails(token, stripeToken)),
  getTokenStatus: (token) => dispatch(getTokenStatus(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalCardContainer);
