import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { CardElement } from '@stripe/react-stripe-js';

import 'react-select/dist/react-select.css';
import 'react-virtualized-select/styles.css';
// Then import the virtualized Select HOC
import Select from 'react-virtualized-select';
import { fetchCountries } from '../../../data/resources/actions';
import './CreditCardForm.css';
import StripeLogo from '../StripeLogo';
import http from '../../../services/util/http';

class CreditCardForm extends Component {
  static propTypes = {
    stripe: PropTypes.shape({
      confirmCardSetup: PropTypes.func.isRequired,
    }),
    elements: PropTypes.shape({
      getElement: PropTypes.func.isRequired,
    }),
    fetchCountries: PropTypes.func.isRequired,
    addNewCardToItinerary: PropTypes.func.isRequired,
    countries: PropTypes.array,
  };

  static defaultProps = {
    stripe: null,
    elements: null,
    countries: [],
  };

  state = {
    name: '',
    zip: '',
    country: '',
    error: '',
    tokenRequestSubmitted: false,
  };

  UNSAFE_componentWillMount() {
    if (this.props.countries.length === 0) {
      this.props.fetchCountries();
    }
  }

  handleChange = ({ target }) => {
    const { name } = target;
    this.setState({
      [name]: target.value,
    });
  };

  submit = async () => {
    if (!this.validateNonStripeFields() || this.state.tokenRequestSubmitted) {
      return;
    }
    try {
      this.setState({ tokenRequestSubmitted: true });
      const { elements, stripe } = this.props;
      const response = await http.get('/api/billing/client-secret');
      const { secret } = response.body;
      const { setupIntent, error } = await stripe.confirmCardSetup(
        secret,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: this.state.name,
              address: {
                postal_code: this.state.zip,
                country: this.state.country.value,
              },
            },
          },
        },
      );
      if (error) {
        this.setState({ tokenRequestSubmitted: false, error: `Error encountered: ${error.message}` });
        return;
      }
      await this.props.addNewCardToItinerary(setupIntent.payment_method);
    } catch (err) {
      this.setState({ tokenRequestSubmitted: false, error: 'There was an error saving your card.' });
    }
  };

  validateNonStripeFields() {
    this.setState({ error: '' });
    if (!this.state.name) {
      this.setState({ error: 'Please provide name on card' });
      return false;
    }
    if (!this.state.country) {
      this.setState({ error: 'Please select the billing country' });
      return false;
    }
    if (!this.state.zip) {
      this.setState({ error: 'Please provide zip on card' });
      return false;
    }
    return true;
  }

  render() {
    if (!this.props.stripe || !this.props.elements) return null;
    return (
      <div>
        <div className="key-ccform-header">
          <div className="key-ccform-title">Add A Card</div>
          <div>
            This will add a new credit card to your existing KEY user account.
          </div>
        </div>
        <div className="key-credit-card-form">
          <div className="key-ccform-billing-details">
            <div className="key-ccform-section-name">
              <div className="key-ccform-label">Name on Card</div>
              <div className="key-ccform-field-name">
                <input
                  type="text"
                  name="name"
                  placeholder="First Last"
                  onChange={this.handleChange}
                  value={this.state.name}
                />
              </div>
            </div>
            <div className="key-ccform-section-country">
              <div className="key-ccform-label">Country</div>
              <Select
                options={this.props.countries}
                onChange={(country) => this.setState({ country })}
                value={this.state.country}
                className="key-ccform-dropdown"
              />
            </div>
            <div className="key-ccform-section-zip">
              <div className="key-ccform-label">Zip</div>
              <div className="key-ccform-field-zip">
                <input
                  type="text"
                  name="zip"
                  placeholder="XXXXX"
                  onChange={this.handleChange}
                  value={this.state.zip}
                />
              </div>
            </div>
          </div>
          <div className="key-ccform-label">Card Details</div>
          <div className="key-credit-card-details key-ccform-field">
            <CardElement options={{ hidePostalCode: true }} />
          </div>
          <StripeLogo />
          <div className="key-ccform-error-text">{this.state.error}</div>
          <div className="key-ccform-button-container">
            <button type="button" disabled={!this.props.stripe} className="key-ccform-submit" onClick={this.submit}>
              Add Card
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ countries: state.countries });

const mapDispatchToProps = (dispatch) => ({
  fetchCountries: () => dispatch(fetchCountries()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardForm);
