import React from 'react';
import { Link } from 'react-router-dom';

import './pageNotFound.css';

export default function PageNotFound() {
  return (
    <div className="page-not-found">
      <header className="header">
        <h1>404 Page Not Found</h1>
      </header>
      <div className="page-content">
        <h2>Oops!</h2>
        <h4>We can&rsquo;t seem to find the page you are looking for.</h4>
        <p>Go back or visit our <Link to="/"> homepage</Link>.</p>
      </div>
    </div>
  );
}
