/**
 * @module Helper functions to read and write cookies.
 */

/**
 * @returns {*} The value stored in the browser cookie.
 */
const readCookie = name => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

/**
 * Stores the cookie within the parent domain name. The cookie can be accessed
 * by the that domain as well as other subdomains within it.
 */
const saveCookie = ({ cookieName, cookieValue }) => {
  const domainName = getCookieDomainName();
  document.cookie = `${cookieName}=${cookieValue};domain=${domainName};path=/`;
};

/**
 * @returns {string} The top-level domain name for the current host.
 */
const getCookieDomainName = () => {
  // Match hosts like login.key.co or login.dev-key.co
  const hostnameWithSudomainMatch = /(\..*key\.co)$/.exec(window.location.hostname);
  if (hostnameWithSudomainMatch) {
    return hostnameWithSudomainMatch[1];
  }
  // Match hosts like key.co or dev-key.co
  const hostnameWithoutSudomainMatch = /(.*key\.co)$/.exec(window.location.hostname);
  return hostnameWithoutSudomainMatch[1];
};

module.exports = {
  readCookie,
  saveCookie,
};
