import { camelize } from '../services/util';
import { track } from '../services/analytics';

export default function withBilling(WrappedComponent) {
  return class BillingHOC extends WrappedComponent {
    UNSAFE_componentWillMount() {
      if (super.UNSAFE_componentWillMount) {
        super.UNSAFE_componentWillMount();
      }
      if (this.state) {
        this.setState((prevState) => ({ fields: { ...prevState.fields } }));
      } else {
        this.setState({ fields: {} });
      }
    }

    billingFieldData = [
      { name: 'Cardholder Name', required: true },
      { name: 'Credit Card Number', required: true },
      { name: 'Expiration Date', required: true },
      { name: 'Security Code', required: true },
      { name: 'City', required: true },
      { name: 'Zip Code', required: true },
    ];

    isValid = () => {
      const requiredFields = [];
      const fieldArray = this.billingFieldData;
      fieldArray.forEach((field) => {
        if (field.required) {
          requiredFields.push(camelize(field.name));
        }
      });
      const validArray = requiredFields.map((field) => {
        const value = this.state.fields[field];
        switch (field) {
          case 'zipCode':
            if (!value) return false;
            return value.length >= 2;
          case 'expirationDate':
            if (!value) return false;
            return value.length >= 5;
          default:
            return !!value;
        }
      });
      return validArray.indexOf(false) < 0;
    }

    fieldChanged = (target) => {
      const isBillingField = !!this.billingFieldData.find((data) => data.name === target.name);
      if (isBillingField) {
        track('Credit Card Form Edited', {
          name: target.name,
        });
      } else {
        track('Account Information Form Edited', {
          name: target.name,
        });
      }
      const newFieldState = { ...this.state.fields };
      if (!!target.value && target.value !== '') {
        newFieldState[camelize(target.name)] = target.value;
      } else {
        delete newFieldState[camelize(target.name)];
      }
      this.setState({ fields: { ...newFieldState } });
    }
  };
}
