import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import './DropdownInput.css';

class DropdownInput extends Component {
  static propTypes = {
    selection: PropTypes.string,
    options: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    placeholder: PropTypes.string.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    classNameProp: PropTypes.string,
  };

  static defaultProps = {
    selection: null,
    classNameProp: null,
  };

  constructor(props) {
    super(props);
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleOptionSelected = this.handleOptionSelected.bind(this);
  }

  state = {
    isExpanded: false,
    selectedText: null,
  };

  UNSAFE_componentWillMount() {
    // Apply initial selection, if specified via props.
    this.initializeSelection(this.props.options, this.props.selection);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Apply initial selection, if specified via props.
    this.initializeSelection(nextProps.options, nextProps.selection);
  }

  /**
   * This handler is called by the onClickOutside React Higher Order Component (HOC)
   * that wraps this component.
   */
  handleClickOutside = () => {
    this.setState({ isExpanded: false });
  };

  handleButtonPress() {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  handleOptionSelected({ selectedText }) {
    this.setState({
      selectedText,
      isExpanded: false,
    });
  }

  initializeSelection = (options, selection) => {
    if (selection) {
      const selectedEntry = options.find((o) => o.value === selection);
      if (selectedEntry) {
        this.setState({
          selectedText: selectedEntry.text,
        });
      }
    }
  }

  render() {
    return (
      <div className={`dropdown-input ${this.props.classNameProp}`}>
        <button
          className="dropdown-toggle"
          type="button"
          onClick={this.handleButtonPress}
          disabled={this.props.disabled}
        >
          {
            this.state.selectedText
              ? (<div>{this.state.selectedText}</div>)
              : (<div className="dropdown-placeholder">{this.props.placeholder}</div>)
          }
          <div className={`input-caret ${this.props.classNameProp}`} />
        </button>
        <div className="dropdown-menu" style={{ visibility: this.state.isExpanded ? 'visible' : 'hidden' }}>
          <div className="arrow" />
          <div className="dropdown-menu-content">
            {
              this.props.options.map((o) => (
                <button
                  type="button"
                  className="dropdown-item"
                  onClick={() => {
                    this.handleOptionSelected({ selectedText: o.text });
                    this.props.onValueChanged(o.value);
                  }}
                  key={o.value}
                >
                  {o.text}
                </button>
              ))
            }
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(DropdownInput);
