import {
  SET_USER, SET_USER_INFO, LOG_OUT, SET_LEAD_ID, SET_NOT_LOGGED_IN, SET_LEAD_ERROR,
} from './actions';

function user(state = {
  /* Initial logged-in state is unknown until confirmed via API. */
  loggedIn: null,
  id: null,
  leadError: null,
}, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        loggedIn: true,
        id: action.id,
        firstName: action.firstName,
        lastName: action.lastName,
        phone: action.phone,
        username: action.username,
      };
    case SET_NOT_LOGGED_IN:
      return {
        ...state,
        loggedIn: false,
      };
    case SET_USER_INFO:
      return { ...state, ...action.userInfo };
    case SET_LEAD_ID:
      return {
        ...state,
        leadId: action.leadId,
      };
    case SET_LEAD_ERROR:
      return {
        ...state,
        leadError: action.error,
      };
    case LOG_OUT:
      return {
        ...state,
        loggedIn: false,
        id: null,
        firstName: null,
        lastName: null,
        phone: null,
        username: null,
      };
    default:
      return state;
  }
}

export default user;
