import Notifications from 'react-notification-system-redux';
import restClient from '../../services/util/http';

export const SET_COUNTRIES = 'SET_COUNTRIES';

const setCountryList = (countryList) => ({
  type: SET_COUNTRIES,
  payload: countryList,
});

export const fetchCountries = () => (dispatch) => restClient.get('/api/countries')
  .then((res) => dispatch(setCountryList(res.body)))
  .catch((err) => {
    dispatch(Notifications.error({
      title: 'Error encountered',
      message: `Failed to load the list of countries: ${err}`,
    }));
  });
