import http from '../../services/util/http';

export const SET_SOURCE_ITINERARY = 'SET_SOURCE_ITINERARY';
export const LOADING_SOURCE_ITINERARY = 'LOADING_SOURCE_ITINERARY';

const setSourceItinerary = (sourceItinerary) => ({
  type: SET_SOURCE_ITINERARY,
  payload: sourceItinerary,
});

const loadingSourceItinerary = (isLoading) => ({
  type: LOADING_SOURCE_ITINERARY,
  payload: isLoading,
});

export const getSourceItinerary = (sourceId) => async (dispatch) => {
  dispatch(loadingSourceItinerary(true));
  try {
    const result = await http.get(`/api/itinerary/source/${sourceId}`);
    const source = result.body;
    dispatch(setSourceItinerary(source));
  } catch (err) {
    console.log(err); // eslint-disable-line no-console
  }
  dispatch(loadingSourceItinerary(false));
};
