import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundDiv } from '@keydotco/key-components';

import GoldNumbers from './GoldNumbers';

import './styles.css';

const NumbersSection = ({ markets }) => (
  <BackgroundDiv
    imageURL="https://static.key.co/img/home/hot-air-balloons-3.jpg"
    className="homepage-section numbers-section"
  >
    <div className="container no-max">
      <div className="gold-border-box">
        <div className="info-text-box">
          <p>
            We pride ourselves in making our customers happy,
            partnering with amazing brands, and supporting local businesses.
          </p>
          <div className="numbers-row">
            {
            markets.length > 0 && (
              <GoldNumbers
                topLabel="We are in"
                number={markets.length.toString()}
                bottomLabel="Destinations"
              />
            )
          }
            <GoldNumbers
              topLabel="We have"
              number="112"
              bottomLabel="Partners"
            />
            <GoldNumbers
              topLabel="We work with"
              number="744+"
              bottomLabel="Local Businesses"
            />
          </div>
        </div>
      </div>
    </div>
  </BackgroundDiv>
);

NumbersSection.propTypes = {
  markets: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default NumbersSection;
