import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import { BackgroundDiv } from '@keydotco/key-components';
import checkIcon from '../../../assets/icons/check.svg';
import openCircleIcon from '../../../assets/icons/open-circle.svg';
import './UserInterestButton.css';

export default class GroupingButton extends Component {
  static propTypes = {
    caption: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  handleOnClick = () => {
    this.props.onClick({
      value: this.props.value,
      newSelectedState: !this.props.selected,
    });
  };

  render() {
    return (
      <BackgroundDiv
        role="button"
        className={`grouping-content grouping-button ${this.props.selected ? 'selected' : ''}`}
        imageURL={this.props.imageUrl}
        fixedWidth={500}
        onClick={this.handleOnClick}
      >
        {
          this.props.selected
            ? <InlineSVG className="grouping-check" src={checkIcon} />
            : <InlineSVG className="grouping-check" src={openCircleIcon} />
        }
        <div className="image-caption">{this.props.caption}</div>
      </BackgroundDiv>
    );
  }
}
