import assign from 'core-js-pure/features/object/assign';

const isProd = process.env.APP_ENV === 'production';

/**
 * Wrapper for all analytics calls. We don't want analytics event errors to bring down the app.
 */
const tryCatchWrap = (callback) => {
  try {
    callback();
  } catch (error) {
    console.error(error); // eslint-disable-line no-console
  }
};

export function setupAnalytics() {
  tryCatchWrap(() => {
    // Set up Segment tag
    if (window.analytics) {
      window.analytics.load(process.env.SEGMENT_WRITE_KEY);
    }
  });
}

export function identify(email, name) {
  tryCatchWrap(() => {
    if (window.analytics) {
      window.analytics.identify(email, {
        name,
        email,
      });
    }
  });
}

export function track(event, ...properties) {
  tryCatchWrap(() => {
    if (window.analytics) {
      window.analytics.track(event, assign({}, ...properties));
    }
  });
}

export function trackError(errorName, errorMessage, errorCode) {
  track('error', {
    errorCode,
    errorName,
    errorMessage,
  });
}

export function trackErrorV2(error, ...properties) {
  track('error', assign({
    errorMessage: error.message,
    requestUrl: error.url,
    requestMethod: error.method,
    requestStatus: error.status,
  }, ...properties));
}

export function pageEvent(name, ...properties) {
  tryCatchWrap(() => {
    if (window.analytics) {
      window.analytics.page(name, assign({}, ...properties));
    }
  });
  tryCatchWrap(() => {
    if (isProd && window.piTracker) {
      window.piTracker(window.location.href, name, assign({}, ...properties));
    }
  });
}

export function trackLink(link, name, ...properties) {
  tryCatchWrap(() => {
    if (window.analytics) {
      window.analytics.trackLink(link, name, assign({}, ...properties));
    }
  });
}

export function generateContextForPage(pageName, reactProps) {
  return ({
    page: pageName,
    path: reactProps.location.pathname,
  });
}
