import React from 'react';

export default function TokenUsed() {
  return (
    <div className="additional-card key-card-container">
      <div className="key-card is-centered">
        <div>
          <h2>Token Used</h2>
          <p>You have already used this token. Please Contact us.</p>
        </div>
      </div>
    </div>
  );
}
