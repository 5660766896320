import React from 'react';
import PropTypes from 'prop-types';
import { BackgroundDiv, Button } from '@keydotco/key-components';

import './styles.css';
import Slider from 'react-slick/lib';

const InfoSectionStepCard = ({
  stepNumber,
  stepTitle,
  header,
  subtext,
  ctaLabel,
  onCTAClick,
  images,
  collage,
}) => {
  const title = stepNumber ? `Step ${stepNumber}` : stepTitle;
  const slides = images.map((image) => (
    <div className="step-carousel-image">
      <BackgroundDiv imageURL={image.url} fixedWidth={600} withGradient>
        <p>{image.title}</p>
      </BackgroundDiv>
    </div>
  ));
  const settings = {
    slidesToShow: 1,
    adaptiveHeight: true,
    arrows: false,
    infinite: true,
    lazyLoad: 'ondemand',
    height: 400,
    dots: slides.length > 1,
    dotsClass: 'carousel-dots',
  };
  return (
    <>
      <div className="step-card-container">
        <div className={`step-number${stepNumber ? '' : ' without-number'}`}>{title}</div>
        {stepNumber && <div className="step-title">{stepTitle}</div> }
        {collage}
        <h2>{header}</h2>
        <p className="step-subtext">{subtext}</p>
        {ctaLabel && onCTAClick && (
          <Button onClick={onCTAClick}>
            {ctaLabel}
          </Button>
        )}
      </div>
      <div className="step-card-container-mobile">
        <div className="step-number">{title}</div>
        {stepNumber && <div className="step-title">{stepTitle}</div> }
        <Slider {...settings}>
          {slides}
        </Slider>
        <h2>{header}</h2>
        <p className="step-subtext">{subtext}</p>
        {ctaLabel && onCTAClick && (
          <Button onClick={onCTAClick}>
            {ctaLabel}
          </Button>
        )}
      </div>
    </>
  );
};

InfoSectionStepCard.propTypes = {
  stepNumber: PropTypes.number,
  stepTitle: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
  ctaLabel: PropTypes.string,
  onCTAClick: PropTypes.func,
  images: PropTypes.arrayOf(PropTypes.string),
  collage: PropTypes.element,
};

InfoSectionStepCard.defaultProps = {
  stepNumber: null,
  ctaLabel: null,
  onCTAClick: null,
  images: [],
  collage: null,
};

export default InfoSectionStepCard;
