import get from 'lodash/get';
import Notifications from 'react-notification-system-redux';
import { identify } from '../../services/analytics';
import restClient from '../../services/util/http';
import sso from '../../sso';

export const SET_USER = 'SET_USER';
export const SET_NOT_LOGGED_IN = 'SET_NOT_LOGGED_IN';
export const SET_USER_INFO = 'SET_USER_INFO';
export const LOG_OUT = 'LOG_OUT';

export const SET_LEAD_ID = 'SET_LEAD_ID';
export const SET_LEAD_ERROR = 'SET_LEAD_ERROR';

function setUser(user) {
  identify(user.username, `${user.firstName} ${user.lastName}`);
  return {
    type: SET_USER,
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
    username: user.username,
  };
}

function setNotLoggedIn() {
  return {
    type: SET_NOT_LOGGED_IN,
  };
}

export function setUserInfo(userInfo) {
  return {
    type: 'SET_USER_INFO',
    userInfo,
  };
}

export function logOut() {
  return () => sso.logout();
}

export function checkUser() {
  return (dispatch) => {
    sso
      .getUser({ role: null })
      .then((currentUser) => {
        dispatch(setUser(currentUser));
        return null;
      }).catch((error) => {
        if (error) {
          console.error(error); // eslint-disable-line no-console
        }
        // Set "not logged in" state now that we confirmed that there is no active user session.
        dispatch(setNotLoggedIn());
      });
  };
}

export function setLeadId(leadId) {
  return {
    type: SET_LEAD_ID,
    leadId,
  };
}

export function setLeadError(error) {
  return {
    type: SET_LEAD_ERROR,
    error,
  };
}

export const storeCardDetails = (goldieToken, stripeToken) => (dispatch) => (
  restClient.post(`/api/creditCardToken/${goldieToken}`, { stripeToken })
    .catch((error) => {
      const message = get(error.response, 'body.message', get(
        error,
        'message.message',
        get(error, 'message', 'Error saving credit card.'),
      ));
      dispatch(Notifications.error({
        title: 'Error encountered',
        message,
      }));
      throw error;
    })
);

export const getTokenStatus = (token) => () => (
  restClient.get(`/api/creditCardToken/${token}`).then((resp) => resp.body)
);
