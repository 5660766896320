import { trackError } from '../../services/analytics';
import restClient from '../../services/util/http';

export const GET_PARTNER = 'GET_PARTNER';
export const GET_PARTNER_DONE = 'GET_PARTNER_DONE';
export const RECEIVE_PARTNER = 'RECEIVE_PARTNER';
export const SET_PARTNER_ERROR = 'SET_PARTNER_ERROR';

function gettingPartner() {
  return {
    type: GET_PARTNER,
  };
}

function finishedGettingPartner() {
  return {
    type: GET_PARTNER_DONE,
  };
}

function receivePartner(partnerInfo) {
  return {
    type: RECEIVE_PARTNER,
    partnerInfo,
  };
}

function setPartnerError(error) {
  return {
    type: SET_PARTNER_ERROR,
    error,
  };
}

export const getPartner = (partnerDomain) => (dispatch) => {
  dispatch(gettingPartner());
  return restClient.get(`/api/published-partner/${partnerDomain}`)
    .then((response) => {
      dispatch(receivePartner(response));
      return response;
    })
    .catch((error) => {
      if (error.message !== 'Not Found') {
        trackError('getPartner', error.message, error.code);
      }
      dispatch(setPartnerError(error));
    })
    .then(() => dispatch(finishedGettingPartner()));
};
