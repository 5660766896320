import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@keydotco/key-components';

import './IntroNextButtons.css';

const IntroNextButtons = ({
  handleNextClick, setMouseDown, setMouseUp, handleExplore,
}) => (
  <div className="intro-button-group">
    <Button
      variant="secondary"
      onClick={handleNextClick}
      onMouseDown={setMouseDown}
      onMouseUp={setMouseUp}
      onTouchStart={setMouseDown}
      onTouchEnd={setMouseUp}
    >
      Give Me Recommendations
    </Button>
    <Button
      variant="primary"
      onClick={handleExplore}
    >
      Explore Services
    </Button>
  </div>
);

IntroNextButtons.propTypes = {
  handleNextClick: PropTypes.func.isRequired,
  setMouseDown: PropTypes.func.isRequired,
  setMouseUp: PropTypes.func.isRequired,
  handleExplore: PropTypes.func.isRequired,
};

export default IntroNextButtons;
