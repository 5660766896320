import React, { useEffect, useState } from 'react';
import { PromotionalMessage } from '@keydotco/key-components';
import http from '../../services/util/http';
import { track } from '../../services/analytics';

import './styles.css';

const PromotionsSection = () => {
  const [promotion, setPromotion] = useState(null);

  useEffect(() => {
    http.get('/api/promotional-messages/active?placements=Homepage')
      .then((resp) => {
        if (resp.body && resp.body.Homepage) {
          const homepagePromotion = resp.body.Homepage;
          setPromotion(homepagePromotion);
          track('Promotion Viewed', {
            promotion_id: homepagePromotion.objectId,
            name: homepagePromotion.name,
            position: 'Homepage',
          });
        }
        return resp.body;
      }).catch((err) => {
      // eslint-disable-next-line no-console
        console.error(err);
      });
  }, []);

  if (!promotion) {
    return null;
  }

  return (
    <section className="container promotional-message-section">
      <PromotionalMessage
        shortCopy={promotion.shortCopy}
        longCopy={promotion.longCopy}
        hasPopupModal={promotion.hasPopupModal}
        popupTagline={promotion.popupTagline}
        popupTerms={promotion.popupTerms}
        popupAdditionalDetails={promotion.popupAdditionalDetails}
        hasBorderBox
        hasLargeText
        hasLargePadding
        onPopupOpen={() => {
          track('Promotion Clicked', {
            promotion_id: promotion.objectId,
            name: promotion.name,
            position: 'Homepage',
          });
        }}
      />
    </section>
  );
};

export default PromotionsSection;
