// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/load.gif");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".loading-component .loading-icon {\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") center center no-repeat;\n\tbackground-size: 60px;\n\theight: 100%;\n\tposition: relative;\n\tz-index: 100;\n}\n.loading-component:not(.full-screen-overlay), .loading-component:not(.full-screen-overlay) {\n\theight: 100px;\n}\n.loading-component.full-screen-overlay {\n\theight: 100%;\n\tposition: fixed;\n\tz-index: 12\n}\n.loading-component.full-screen-overlay:after {\n\tcontent: '';\n\tbackground: #333333;\n\topacity: 0.75;\n\tposition: fixed;\n\ttop: 0;\n\tbottom: 0;\n\tleft: 0;\n\tright: 0;\n\tz-index: 4;\n}\n.loading-component.full-screen-overlay .loading-container {\n\tbottom: 0;\n\tleft: 0;\n\tposition: fixed;\n\tright: 0;\n\ttop: 0;\n}\n.loading-component.parent-size-overlay .loading-container {\n\theight: 100%;\n\tposition: relative;\n\twidth: auto;\n}\n.loading-component.parent-size-overlay .loading-container:after {\n\tbackground: #333333;\n\tbottom: 0;\n\tcontent: '';\n\tleft: 0;\n\topacity: 0.9;\n\tposition: absolute;\n\tright: 0;\n\ttop: 0;\n\tz-index: 4;\n}\n", ""]);
// Exports
module.exports = exports;
