import devContent from '../../../content/dev.yaml';
import prodContent from '../../../content/prod.yaml';

const isProd = process.env.APP_ENV === 'production';

export function getContent() {
  if (isProd) return prodContent;
  return devContent;
}

export function camelize(str) {
  if (typeof str !== 'string') {
    return str;
  }
  let camelString = str.replace("'", '');
  camelString = camelString.replace('"', '');
  camelString = camelString.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (Number(match) === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
  return camelString;
}

const setUrlParam = (params, paramLabel, paramValue) => {
  if (paramValue) {
    if (Array.isArray(paramValue)) {
      params.delete(paramLabel);
      paramValue.forEach((pValue) => params.append(paramLabel, pValue));
    } else {
      params.set(paramLabel, paramValue);
    }
  } else {
    params.delete(paramLabel);
  }
};

export const generateHref = (newURL) => {
  const oldParams = new URLSearchParams(window.location.search);
  const url = new URL(newURL);
  const urlSearchParams = url.searchParams;
  [...oldParams].forEach(([key, value]) => setUrlParam(urlSearchParams, key, value));
  return url.toString();
};

export function kebabize(str) {
  let result = str;

  // Convert camelCase capitals to kebab-case.
  result = result.replace(/([a-z][A-Z])/g, (match) => `${match.substr(0, 1)}-${match.substr(1, 1).toLowerCase()}`);

  // Convert non-camelCase capitals to lowercase.
  result = result.toLowerCase();

  // Convert non-alphanumeric characters to hyphens
  result = result.replace(/[^-a-z0-9]+/g, '-');

  // Remove hyphens from both ends
  result = result.replace(/^-+/, '').replace(/-$/, '');

  return result;
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function validatePhone(phone) {
  const count = (phone.match(/\d/g) || []).length;
  return count > 9 && count < 15;
}

export function goToNextInput(event) {
  if (event.charCode === 13 && !!event.target.parentElement.nextSibling && !!event.target.parentElement.nextSibling.querySelector('input')) {
    event.target.parentElement.nextSibling.querySelector('input').focus();
  }
}

export function createMarkup(markup) {
  const alteredMarkup = markup.replace(/href="/g, 'target="_blank" href="');
  const innerHtml = { __html: alteredMarkup };
  return innerHtml;
}
