import Set from 'core-js-pure/features/set';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import URLSearchParamsPolyfill from 'url-search-params';
import isEmpty from 'lodash/isEmpty';

import './QuestionnaireView.css';
import { nextStep, prevStep } from '../../data/questionnaire/actions';
import IntroPanel from './IntroPanel/IntroPanel';
import NameEmailPanel from './NameEmailPanel';
import UserInterestPanel from './UserInterestPanel';
import { trackErrorV2 } from '../../services/analytics';
import { kebabize } from '../../services/util';
import { saveCookie } from '../../lib/single-sign-on/cookies';
import restClient from '../../services/util/http';

class QuestionnaireView extends Component {
  static propTypes = {
    user: PropTypes.shape({
      loggedIn: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      username: PropTypes.string,
      phone: PropTypes.string,
    }),
    subdomain: PropTypes.string,
    prefillMarket: PropTypes.string,
    prefillStartDate: PropTypes.string,
    prefillEndDate: PropTypes.string,
    prefillPropertyType: PropTypes.string,
    showError: PropTypes.func.isRequired,
    analyticsContext: PropTypes.shape({
      page: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
    }).isRequired,
    leadId: PropTypes.string,
    productLine: PropTypes.string,
    partner: PropTypes.object.isRequired,
    leadPartner: PropTypes.shape({}),
    currentPage: PropTypes.number.isRequired,
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    markets: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      isActiveForPM: PropTypes.bool,
    })),
    loadingMarkets: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    user: {
      firstName: '',
      lastName: '',
      email: '',
    },
    prefillMarket: null,
    prefillStartDate: null,
    prefillEndDate: null,
    prefillPropertyType: null,
    subdomain: 'key',
    leadId: null,
    productLine: null,
    markets: [],
    leadPartner: null,
  };

  state = {
    occasionOptions: [],
    marketOptions: this.props.markets
      .map((m) => ({ value: m.id, displayValue: m.name, ...m })),
    groupingOptions: [],
    loaded: !this.props.loadingMarkets,
    // Step 1
    market: QuestionnaireView.getMarket(
      this.props.prefillMarket,
      this.props.markets.map((m) => ({ value: m.id, displayValue: m.name, ...m })),
    ),
    occasion: null,
    adultCount: 0,
    teenCount: 0,
    childCount: 0,
    infantCount: 0,
    // Step 2
    property_type: null,
    groupings: new Set(),
    description: '',
    // Step 3
    firstName: '',
    lastName: '',
    email: '',
    startDate: this.props.prefillStartDate,
    endDate: this.props.prefillEndDate,
    phone: '',
    loadedOccasions: false,
  };

  UNSAFE_componentWillMount() {
    restClient.get('/api/occasion').then((res) => {
      this.setState({
        loadedOccasions: true,
        loaded: !this.props.loadingMarkets,
        occasionOptions: res.body.map((o) => ({ value: o.id, displayValue: o.name })),
      });
      return res;
    }).catch(this.handleError);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // Merge properties individually since props are set asynchronously when
    // the lead fetch request returns.
    this.setState((prevState) => ({
      firstName: prevState.firstName || nextProps.user.firstName,
      lastName: prevState.lastName || nextProps.user.lastName,
      email: prevState.email
        || (nextProps.user.username
            && this.isTemporaryUsername(nextProps.user) ? '' : nextProps.user.username),
      phone: prevState.phone || nextProps.user.phone,
      startDate: prevState.startDate || nextProps.prefillStartDate,
      endDate: prevState.endDate || nextProps.prefillEndDate,
      property_type: prevState.property_type || nextProps.prefillPropertyType,
      market: prevState.market
        || QuestionnaireView.getMarket(nextProps.prefillMarket, prevState.marketOptions),
      loaded: prevState.loadedOccasions && !nextProps.loadingMarkets,
      marketOptions: nextProps.markets
        .map((m) => ({
          value: m.id,
          displayValue: m.name,
          isActiveForPM: m.isActiveForPM,
          status: m.status,
        })),
    }));
  }

  getUserInterestQueryParams() {
    let queryParams = `?market=${this.state.market}&occasion=${this.state.occasion}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`;
    if (this.state.adultCount > 0) {
      queryParams += '&guestAges=adults';
    }
    if (this.state.childCount > 0) {
      queryParams += '&guestAges=children';
    }
    if (this.state.teenCount > 0) {
      queryParams += '&guestAges=teens';
    }
    if (this.state.infantCount > 0) {
      queryParams += '&guestAges=infants';
    }
    return queryParams;
  }

  getMarketOptions = () => {
    const filteredOptions = this.state.marketOptions.filter((m) => m.isActiveForPM);
    if (filteredOptions.length === 0) {
      // We don't ever want to show the market dropdown with no options so fall back to showing
      // all market options if none are 'active for PM'
      return this.state.marketOptions.filter((m) => m.status === 'active');
    }
    return filteredOptions;
  };

  getPanelForIndex = (index) => {
    switch (index) {
      case 1:
        return (
          <UserInterestPanel
            onNext={this.handleNext}
            onBack={this.handleBack}
            onChange={(values) => this.setState(values)}
            groupingOptions={this.state.groupingOptions}
            step={index}
            groupings={this.state.groupings}
            property_type={this.state.property_type}
            description={this.state.description}
            analyticsContext={{
              step_index: 20,
              market_id: this.state.market,
              ...this.props.analyticsContext,
            }}
          />
        );
      case 2:
        return (
          <NameEmailPanel
            onNext={this.handleNext}
            onBack={this.handleBack}
            onChange={(values) => this.setState(values)}
            step={index}
            loggedIn={this.props.user.loggedIn}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            email={this.state.email}
            phone={this.state.phone}
            errorMessage={this.state.errorMessage}
            isSubmitted={this.state.isSubmitted}
            analyticsContext={{
              step_index: 30,
              market_id: this.state.market,
              ...this.props.analyticsContext,
            }}
          />
        );
      default: {
        return (
          <IntroPanel
            showMarketAndDates={
              !this.props.prefillMarket
              || !this.props.prefillEndDate
              || !this.props.prefillStartDate
            }
            onNext={this.handleNext}
            onChange={(values) => this.setState(values)}
            disabled={this.isDisabled()}
            step={index}
            loaded={this.state.loaded}
            occasionOptions={this.state.occasionOptions}
            marketOptions={this.getMarketOptions()}
            occasion={this.state.occasion}
            market={this.state.market}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            adultCount={this.state.adultCount}
            teenCount={this.state.teenCount}
            childCount={this.state.childCount}
            infantCount={this.state.infantCount}
            analyticsContext={{
              step_index: 10,
              market_id: this.state.market,
              ...this.props.analyticsContext,
            }}
            partner={this.props.partner.partnerPage ? this.props.partner : this.props.leadPartner}
            partnerSubdomain={this.props.subdomain === 'key' ? null : this.props.subdomain}
          />
        );
      }
    }
  };

  static getMarket(prefillMarket, marketOptions) {
    let result;
    const marketOptionsForPM = marketOptions.filter((m) => m.isActiveForPM);
    if (marketOptionsForPM && marketOptionsForPM.length === 1) {
      return marketOptionsForPM[0].value;
    }
    if (prefillMarket && marketOptions && marketOptions.length > 0) {
      result = marketOptions.find(
        (market) => kebabize(market.displayValue) === kebabize(prefillMarket),
      );
      return result ? result.value : null;
    }
    return null;
  }

  getDeclinedInterests() {
    const declined = new Set(this.state.groupingOptions.map((interest) => interest.value));
    this.state.groupings.forEach((selected) => declined.delete(selected));
    return declined;
  }

  isTemporaryUsername = (user) => (
    !user.loggedIn
      && (user.username.includes('@guest.airbnb.com') || user.username.includes('@guest.booking.com'))
  );

  handleNext = () => {
    const nextPage = this.props.currentPage + 1;
    if (nextPage > 2) {
      this.handleFinalizeFlow();
    } else if (nextPage === 1) {
      restClient.get(`/api/userinterest/${this.getUserInterestQueryParams()}`).then((res) => {
        const userInterests = res.body.map((userInterest) => ({
          value: userInterest.objectId,
          displayValue: userInterest.name,
          stringId: userInterest.stringId,
          serviceName: userInterest.service && userInterest.service.name,
          serviceId: userInterest.service && userInterest.service.objectId,
          imageUrl: userInterest.questionnaireImage,
        }));
        this.setState({
          groupingOptions: userInterests,
          groupings: new Set(),
        });
        this.props.nextStep();
        return null;
      }).catch(this.handleError);
    } else {
      this.props.nextStep();
    }
  };

  handleError = (error) => {
    const errorMessage = 'We\'re sorry. The service is currently unavailable, but we\'re working on it. If you need urgent assistance, please email requests@key.co';
    this.props.showError('Error', errorMessage);
    this.setState({
      errorMessage,
    });
    trackErrorV2(error, this.props.analyticsContext);
  };

  handleFinalizeFlow = () => {
    this.setState({
      isSubmitted: true,
    });
    const occasionSelection = this.state.occasionOptions.find(
      (occasion) => occasion.value === this.state.occasion,
    );
    const groupingArray = [...this.state.groupings];
    const selectedInterests = this.state.groupingOptions.filter(
      (option) => groupingArray.indexOf(option.value) > -1,
    );
    const urlParams = new URLSearchParamsPolyfill(window.location.search);
    const sendData = {
      leadId: this.props.leadId,
      productLine: this.props.productLine,
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      phone: this.state.phone,
      market: this.state.market,
      occasion: this.state.occasion,
      occasionName: occasionSelection.stringId,
      details: this.state.description,
      property_type: this.state.property_type,
      start_date: this.state.startDate,
      end_date: this.state.endDate || this.state.startDate,
      selected_interests: [...this.state.groupings],
      selected_interest_details: selectedInterests,
      declined_interests: [...this.getDeclinedInterests()],
      partner: this.props.partner && !isEmpty(this.props.partner) ? this.props.partner.id : null,
      adultCount: this.state.adultCount,
      teenCount: this.state.teenCount,
      childCount: this.state.childCount,
      infantCount: this.state.infantCount,
      sourceOpportunityId: urlParams.get('sourceOpportunityId'),
      leadSource: urlParams.get('leadSource'),
    };
    restClient.post('/api/questionnaire', sendData).then((res) => {
      if (res && res.body && res.body.itinerary) {
        if (res.body.sessionToken) {
          saveCookie({
            cookieName: process.env.SSO_COOKIE_NAME,
            cookieValue: res.body.sessionToken,
          });
        }
        urlParams.set('keysite_content', 'questionnaire');
        if (this.props.subdomain && this.props.subdomain !== 'key') {
          urlParams.set('partner', this.props.subdomain);
        }
        window.location.href = `${process.env.ITINERARY_ENDPOINT}/itineraries/${res.body.itinerary.objectId}?${urlParams.toString()}`;
        return null;
      }
      throw new Error('unable to find itinerary in questionnaire response');
    }).catch((err) => {
      this.handleError(err);
      this.setState({
        isSubmitted: false,
      });
    });
  };

  handleBack = () => {
    this.props.prevStep();
  };

  isDisabled = () => !this.state.loaded;

  render() {
    return (
      <div className={`questionnaire-flow page-${this.props.currentPage}`}>
        {this.getPanelForIndex(this.props.currentPage)}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentPage: state.questionnaire,
  activeDestinations: state.destinations.destinations,
});

const mapDispatchToProps = (dispatch) => ({
  nextStep: () => dispatch(nextStep()),
  prevStep: () => dispatch(prevStep()),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireView);
