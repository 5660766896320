import React from 'react';
import PropTypes from 'prop-types';
import InlineSVG from 'svg-inline-react';
import './GuestCountDropdown.css';
import AddCircle from '../../../assets/icons/round-add_circle.svg';
import RemoveCircle from '../../../assets/icons/round-remove_circle.svg';

const CounterText = (props) => (
  <div className="counter-text">
    <button
      type="button"
      className="counter-button"
      onClick={() => props.handleCountEntered(props.value, props.count - 1)}
    >
      <InlineSVG src={RemoveCircle} className="circle-button" />
    </button>
    <input
      className="counter-text-field"
      type="number"
      placeholder="0"
      value={(props.count > 0) ? props.count : ''}
      onChange={(event) => props.handleCountEntered(props.value, parseInt(event.target.value, 10))}
    />
    <button
      type="button"
      className="counter-button"
      onClick={() => props.handleCountEntered(props.value, props.count + 1)}
    >
      <InlineSVG src={AddCircle} className="circle-button" />
    </button>
  </div>
);

CounterText.propTypes = {
  value: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  handleCountEntered: PropTypes.func.isRequired,
};

export default CounterText;
