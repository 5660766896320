import React from 'react';
import PropTypes from 'prop-types';
import './styles.css';

const GoldNumbers = ({ topLabel, number, bottomLabel }) => (
  <div className="gold-numbers">
    {topLabel && <div className="gold-numbers__top-label">{topLabel}</div>}
    {number && <div className="gold-numbers__number">{number}</div>}
    {bottomLabel && <div className="gold-numbers__bottom-label">{bottomLabel}</div>}
  </div>
);

GoldNumbers.propTypes = {
  topLabel: PropTypes.string,
  number: PropTypes.string,
  bottomLabel: PropTypes.string,
};

GoldNumbers.defaultProps = {
  topLabel: '',
  number: '',
  bottomLabel: '',
};

export default GoldNumbers;
