import React from 'react';
import AdditionalCard from '../../../components/Billing/AdditionalCard';

export default function CardAddition() {
  return (
    <div className="card-addition">
      <AdditionalCard />
    </div>
  );
}
