import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import InlineSVG from 'svg-inline-react';
import { Testimonial } from '@keydotco/key-components';

import LeftArrow from '../../assets/icons/left-arrow.svg';
import content from './content.json';
import { track } from '../../services/analytics';
import './styles.css';

export default class GuestTestimonialSection extends Component {
  static propTypes = {
    analyticsContext: PropTypes.object,
  }

  static defaultProps = {
    analyticsContext: {},
  }

  state = {
    selectedTabIndex: 0,
    screenWidth: null,
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ screenWidth: window.innerWidth });
  }

  nextTab = () => {
    const { analyticsContext } = this.props;
    track('link_click', { cta: 'Next', section: 'Testimonials' }, analyticsContext);
    this.slider.slickNext();
  }

  previousTab = () => {
    const { analyticsContext } = this.props;
    track('link_click', { cta: 'Previous', section: 'Testimonials' }, analyticsContext);
    this.slider.slickPrev();
  }

  render() {
    const settings = {
      centerMode: this.state.screenWidth >= 1000,
      slidesToShow: 1,
      adaptiveHeight: true,
      arrows: false,
      infinite: true,
      lazyLoad: 'ondemand',
      height: 400,
      beforeChange: (oldIndex, newIndex) => this.setState({ selectedTabIndex: newIndex }),
    };
    const slides = content
      .map(
        (slide) => (
          <div key={slide.name}>
            <Testimonial
              quote={slide.text}
              name={slide.name}
              extraDetail={slide.location}
            />
          </div>
        ),
      );
    return (
      <div className="homepage-section guest-testimonial-section">
        <div className="container">
          <Slider {...settings} ref={(slider) => { this.slider = slider; }}>
            {slides}
          </Slider>
          <div className="arrow-buttons">
            <InlineSVG src={LeftArrow} className="back-arrow" onClick={this.previousTab} />
            <span>{`${this.state.selectedTabIndex + 1}/${slides.length}`}</span>
            <InlineSVG src={LeftArrow} className="forward-arrow" onClick={this.nextTab} />
          </div>
        </div>
      </div>
    );
  }
}
