import React, { Component } from 'react';
import { DateRangeField } from '@keydotco/key-components';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import URLSearchParams from 'url-search-params';

import withIntroWrapper from '.';
import PartnerPageLink from '../PartnerPageLink';
import DropdownInput from '../DropdownInput';
import GuestCountDropdown from '../GuestCountDropdown';
import { track } from '../../../services/analytics';
import './IntroPanel.css';
import RequiredSpeechBubble from '../../RequiredSpeechBubble/RequiredSpeechBubble';
import IntroNextButtons from '../IntroNextButtons';

class IntroPanel extends Component {
  static propTypes = {
    market: PropTypes.string,
    occasion: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    loaded: PropTypes.bool,
    adultCount: PropTypes.number,
    teenCount: PropTypes.number,
    childCount: PropTypes.number,
    infantCount: PropTypes.number,
    marketOptions: PropTypes.array.isRequired,
    occasionOptions: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    analyticsContext: PropTypes.shape({
      page: PropTypes.string.isRequired,
      section: PropTypes.string.isRequired,
      step_index: PropTypes.number.isRequired,
    }).isRequired,
    partner: PropTypes.shape({
      loading: PropTypes.bool.isRequired,
      partnerPage: PropTypes.bool.isRequired,
      logo: PropTypes.string,
      homepageLink: PropTypes.string,
      name: PropTypes.string,
    }),
    setMouseUp: PropTypes.func.isRequired,
    setMouseDown: PropTypes.func.isRequired,
    setRequiredState: PropTypes.func.isRequired,
    showRequiredState: PropTypes.string,
    partnerSubdomain: PropTypes.string,
    showMarketAndDates: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    market: null,
    occasion: null,
    loaded: false,
    adultCount: 0,
    teenCount: 0,
    childCount: 0,
    infantCount: 0,
    startDate: null,
    endDate: null,
    showRequiredState: null,
    partner: {},
    partnerSubdomain: null,
  };

  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  currentInputControl = () => {
    if (!this.props.market) {
      return 'market-input';
    }
    if (!this.props.startDate || !this.props.endDate) {
      return 'date-range-input';
    }
    if (!this.props.occasion) {
      return 'occasion-input';
    }
    return null;
  };

  handleNextClick = () => {
    track('link_click', { cta: 'next' }, this.props.analyticsContext);
    const inputControl = this.currentInputControl();
    if (inputControl != null) {
      // There's still an input value that is not set. Abort handler.
      this.props.setRequiredState(inputControl);
      return;
    }

    track('step_completed', this.props.analyticsContext);

    if (process.env.APP_ENV === 'production') {
      window.pagesense = window.pagesense || [];
      window.pagesense.push(['trackEvent', 'Complete Questionnaire Step 1']);
      window.pagesense.push(['tagRecording', 'Complete Questionnaire Step 1']);
    }

    this.props.onNext();
  };

  handleExplore = () => {
    const inputControl = this.currentInputControl();
    if (inputControl === 'market-input') {
      this.props.setRequiredState(inputControl);
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('keysite_content', 'explore_services');
      if (this.props.startDate) { urlParams.set('start_date', this.props.startDate); }
      if (this.props.endDate) { urlParams.set('end_date', this.props.endDate); }
      if (this.props.partnerSubdomain) { urlParams.set('partner', this.props.partnerSubdomain); }
      track('link_click', { cta: 'explore_services' }, this.props.analyticsContext);
      window.location = `${process.env.ITINERARY_ENDPOINT}/markets/${
        this.props.market
      }/services?${urlParams.toString()}`;
    }
  };

  handlePropertyChanged = (updatedProperty) => {
    this.props.setRequiredState(null);
    this.props.onChange(updatedProperty);
  };

  render() {
    const isPartnerPageLoading = this.props.partner && this.props.partner.loading;
    const isPartnerPage = (this.props.partner && this.props.partner.partnerPage)
          || isPartnerPageLoading;

    return (
      <div className="container">
        <div className={`intro-panel-content ${this.props.loaded ? 'loaded' : 'loading'}`}>
          {isPartnerPage && this.props.partner.logo && (
            <PartnerPageLink
              loading={this.props.partner.loading}
              homePageUrl={this.props.partner.homepageLink}
              logo={this.props.partner.logo}
              name={this.props.partner.name}
            />
          )}
          <h2>
            Book curated experiences and <nobr>in-home</nobr> services all in one place.
          </h2>
          <p>We source the best to make life effortless and moments unforgettable.</p>
          <div className="controls-container">
            <div className="input-prompts">
              {this.props.showMarketAndDates && (
                <div className="intro-input-prompts-row">
                  <div className="input-container">
                    <DropdownInput
                      classNameProp="market-input"
                      placeholder="Destination"
                      options={this.props.marketOptions.map(({ value, displayValue }) => ({
                        value,
                        text: displayValue,
                      }))}
                      onValueChanged={(market) => this.handlePropertyChanged({
                        market,
                      })}
                      disabled={this.props.disabled || this.props.marketOptions.length < 2}
                      selection={this.props.market}
                    />
                    <RequiredSpeechBubble
                      text="Please select your destination"
                      isVisible={this.props.showRequiredState === 'market-input'}
                    />
                  </div>
                  <div className="input-container">
                    <div className="date-range-input">
                      <DateRangeField
                        onChange={(value) => {
                          const [startDate, endDate] = value.content || [];
                          this.handlePropertyChanged({
                            startDate: startDate ? startDate.toJSON() : null,
                            endDate: endDate ? endDate.toJSON() : null,
                          });
                        }}
                        disabled={this.props.disabled}
                        startDate={this.props.startDate}
                        endDate={this.props.endDate}
                        isRequired
                        placeholder="Dates"
                        timeZone={moment.tz.guess()}
                        configOptions={{
                          mode: 'range',
                          showMonths: window.innerWidth < 600 ? 1 : 2,
                          dateFormat: 'MM/DD/YYYY',
                        }}
                        calendarToggleAddOn={(disabled, toggle) => (
                          <div
                            className="input-caret"
                            tabIndex={0}
                            role="button"
                            aria-label="Expand"
                            onClick={toggle}
                          />
                        )}
                      />
                    </div>
                    <RequiredSpeechBubble
                      text="Please select your start and end date"
                      isVisible={this.props.showRequiredState === 'date-range-input'}
                    />
                  </div>
                </div>
              )}
              <div className="intro-input-prompts-row">
                <div className="input-container">
                  <DropdownInput
                    placeholder="Occasion"
                    options={this.props.occasionOptions.map(({ value, displayValue }) => ({
                      value,
                      text: displayValue,
                    }))}
                    onValueChanged={(occasion) => this.handlePropertyChanged({
                      occasion,
                    })}
                    disabled={this.props.disabled}
                    selection={this.props.occasion}
                  />
                  <RequiredSpeechBubble
                    text="Please select your occasion"
                    isVisible={this.props.showRequiredState === 'occasion-input'}
                  />
                </div>
                <GuestCountDropdown
                  onValueChanged={(newCounts) => this.handlePropertyChanged(newCounts)}
                  disabled={this.props.disabled}
                  counts={{
                    adultCount: this.props.adultCount,
                    teenCount: this.props.teenCount,
                    childCount: this.props.childCount,
                    infantCount: this.props.infantCount,
                  }}
                />
              </div>
            </div>
            <IntroNextButtons
              handleExplore={this.handleExplore}
              setMouseUp={this.props.setMouseUp}
              setMouseDown={this.props.setMouseDown}
              handleNextClick={this.handleNextClick}
            />
          </div>
        </div>
      </div>
    );
  }
}

const enhance = withIntroWrapper('intro-panel');
export default enhance(IntroPanel);
