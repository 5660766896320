import React from 'react';
import PropTypes from 'prop-types';

import './PartnerLogo.css';

const PartnerLogo = ({ loading, logo, name }) => (
  <div className={`controls-container ${loading ? 'loading' : 'loaded'}`}>
    <div className="partnered-with">Partnered with</div>
    <img className="partner-logo" src={logo} alt={`${name} logo`} />
  </div>
);

PartnerLogo.propTypes = {
  loading: PropTypes.bool,
  logo: PropTypes.string,
  name: PropTypes.string,
};

PartnerLogo.defaultProps = {
  loading: true,
  logo: 'https://static.key.co/img/key-logo-grey.png',
  name: '',
};

export default PartnerLogo;
