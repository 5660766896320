import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import URLSearchParamsPolyfill from '@ungap/url-search-params';
import { pageEvent } from '../../services/analytics';

const SEO = ({
  title, description, imageUrl, url, twitterCardType, doesSendPageEvent,
}) => {
  useEffect(() => {
    if (doesSendPageEvent) {
      const urlSearchParams = new URLSearchParamsPolyfill(window.location.search);
      const source = urlSearchParams.get('utm_source');
      const leadId = urlSearchParams.get('lead');
      pageEvent({
        hostname: window.location.hostname,
        source,
        title,
        leadId,
        url: window.location.href,
        path: window.location.pathname,
      });
    }
  }, []);

  const getSizedImageUrl = () => {
    let tempUrl = null;
    try {
      tempUrl = new URL(imageUrl);
    } catch {
      return imageUrl;
    }
    const bucket = tempUrl.hostname;
    const key = tempUrl.pathname.substring(1);
    return bucket !== 'static.key.co' ? `https://${bucket}/1280/${key}` : `${tempUrl}?w=1280`;
  };

  const sizedImageUrl = getSizedImageUrl();

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta property="og:image" content={sizedImageUrl} />
      <meta name="twitter:image" content={sizedImageUrl} />
      <meta property="og:url" content={url} />
      <meta name="twitter:card" content={twitterCardType} />
      <meta name="twitter:site" content="@keydotco" />
      <meta property="fb:app_id" content="657342071872805" />
      {process.env.APP_ENV !== 'production' && <meta name="robots" content="noindex" />}
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  twitterCardType: PropTypes.string,
  doesSendPageEvent: PropTypes.bool,
};

SEO.defaultProps = {
  title: '',
  description: '',
  imageUrl: '',
  url: '',
  twitterCardType: 'summary_large_image',
  doesSendPageEvent: true,
};

export default SEO;
