import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import URLSearchParams from 'url-search-params';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import Loading from '../../../components/Loading/Loading';
import { fetchDestinations as getDestinations } from '../../../data/destinations/actions';
import { fetchMarkets as getMarkets } from '../../../data/markets/actions';
import HomesLandingPage from './HomesLandingPage';
import ServicesLandingPage from './ServicesLandingPage';
import { getPartner } from '../../../data/partner/actions';
import { getLeadBySalesforceID } from '../../../data/activeItinerary/actions';

const getSubdomain = () => {
  const subdomain = (/(.*)\..*key\.co$/.exec(window.location.hostname) || [null])[1];
  if (!subdomain || subdomain.toLowerCase() === 'www' || subdomain.toLowerCase() === 'd') {
    return 'key';
  }
  return subdomain;
};

const HomePage = ({
  fetchPartner, fetchLead, getSourceItinerary, fetchDestinations,
  fetchMarkets, lead, leadError, partner, partnerError, isFetchingData,
}) => {
  const params = new URLSearchParams(window.location.search);
  const partnerParam = params.get('partner');
  const leadId = params.get('lead');
  const sourceId = params.get('sourceOpportunityId');
  const subdomain = getSubdomain();
  const [loadingPartner, setLoadingPartner] = useState(partnerParam || subdomain !== 'key');
  const [loadingLead, setLoadingLead] = useState(leadId);

  useEffect(() => {
    fetchDestinations();
    if (subdomain && subdomain !== 'key') {
      fetchPartner(subdomain);
      fetchMarkets(subdomain);
    } else if (partnerParam) {
      fetchPartner(partnerParam);
      fetchMarkets(partnerParam);
    } else {
      fetchMarkets();
    }
    if (leadId) {
      fetchLead(leadId);
    }
    if (sourceId) {
      getSourceItinerary(sourceId);
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(lead) || leadError) {
      setLoadingLead(false);
    }
  }, [lead, leadError]);

  useEffect(() => {
    if (partnerError) {
      setLoadingPartner(false);
    }
    if (partner && !partner.loading) {
      setLoadingPartner(false);
    }
  }, [partner, partnerError]);

  if (isFetchingData) {
    return <Loading fullScreen />;
  }

  if (subdomain === 'travel') {
    return <ServicesLandingPage subdomain={subdomain} />;
  }

  if (loadingPartner || loadingLead) {
    return <Loading fullScreen />;
  }

  if (!loadingPartner && partner && partner.name) {
    return <ServicesLandingPage subdomain={subdomain} />;
  }

  if (!loadingLead && !isEmpty(lead) && lead.isGuestLead && (!lead.partner || lead.partner.status === 'Active')) {
    return <ServicesLandingPage subdomain={subdomain} />;
  }

  return <HomesLandingPage subdomain={subdomain} />;
};

HomePage.propTypes = {
  fetchPartner: PropTypes.func.isRequired,
  fetchLead: PropTypes.func.isRequired,
  getSourceItinerary: PropTypes.func.isRequired,
  fetchDestinations: PropTypes.func.isRequired,
  fetchMarkets: PropTypes.func.isRequired,
  lead: PropTypes.shape({
    isGuestLead: PropTypes.bool,
    partner: PropTypes.shape({
      status: PropTypes.string,
    }),
  }),
  leadError: PropTypes.any.isRequired,
  partner: PropTypes.shape({
    loading: PropTypes.bool,
    name: PropTypes.string,
  }),
  partnerError: PropTypes.any.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
};

HomePage.defaultProps = {
  lead: null,
  partner: null,
};

const mapStateToProps = (state) => ({
  showHomepageSections: state.questionnaire === 0,
  hasLeadSet: state.lead.id,
  lead: state.lead,
  leadError: state.user.leadError,
  partner: state.partner,
  partnerError: state.partner.error,
  markets: state.markets.markets,
  activeDestinations: state.destinations.destinations,
  isFetchingData: state.markets.loading
  || state.destinations.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMarkets: (subdomain) => dispatch(getMarkets(subdomain)),
  fetchDestinations: () => dispatch(getDestinations()),
  fetchPartner: (partnerIdentifier) => dispatch(getPartner(partnerIdentifier)),
  fetchLead: (leadId) => dispatch(getLeadBySalesforceID(leadId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
