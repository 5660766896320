import { SET_LEAD } from './actions';

export default function lead(state = {}, action) {
  switch (action.type) {
    case SET_LEAD:
      return { ...action.source };
    default:
      return state;
  }
}
