import React, { Component } from 'react';
import PropTypes from 'prop-types';
import URLSearchParams from 'url-search-params';
import { BackgroundDiv } from '@keydotco/key-components';

import { track } from '../../services/analytics';
import InfoSectionStepCard from '../InfoSectionStepCard';
import './styles.css';

class VettedHomesSection extends Component {
  static propTypes = {
    partner: PropTypes.string,
    analyticsContext: PropTypes.object,
  };

  static defaultProps = {
    partner: null,
    analyticsContext: {},
  };

  heroImage = 'https://static.key.co/img/home/casa-chanel.jpg';

  goToCatalog = () => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('keysite_content', 'vetted_homes');
    if (this.props.partner) { urlParams.set('partner', this.props.partner); }
    track('link_click', { cta: 'Explore Homes', section: 'Vetted Homes' }, this.props.analyticsContext);
    window.location = `${process.env.ITINERARY_ENDPOINT}/listings?${urlParams.toString()}`;
  };

  render() {
    return (
      <div>
        <BackgroundDiv
          imageURL={this.heroImage}
          className="homepage-section vetted-homes-section"
          withGradient
        >
          <div className="container no-max">
            <div className="vetted-homes-gold-border">
              <div className="container">
                <div className="info-step-container">
                  <InfoSectionStepCard
                    stepNumber={1}
                    header="Arrive to an extraordinary home, hand-selected by our team to ensure your stay is nothing short of luxurious."
                    stepTitle="Book a Vetted Vacation Home"
                    subtext="Browse and reserve a home that satisfies all of your travel preferences, and unlocks exclusive access to our curated catalog of services to elevate your stay."
                    ctaLabel="Explore Homes"
                    onCTAClick={this.goToCatalog}
                    images={[{ url: this.heroImage }]}
                  />
                </div>
              </div>
            </div>
          </div>
        </BackgroundDiv>
      </div>
    );
  }
}

export default VettedHomesSection;
