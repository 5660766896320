import restClient from '../../services/util/http';

export const SET_MARKETS = 'SET_MARKETS';
export const LOADING_MARKETS = 'LOADING_MARKETS';
export const LOADED_MARKETS = 'LOADED_MARKETS';

const loadingMarkets = () => ({
  type: LOADING_MARKETS,
});
const loadedMarkets = () => ({
  type: LOADED_MARKETS,
});

const setMarkets = (marketNames) => ({
  type: SET_MARKETS,
  payload: marketNames,
});

export const fetchMarkets = (identifier) => (dispatch) => {
  dispatch(loadingMarkets());
  return restClient.get(`/api/guest-market?partnerName=${identifier}`)
    .then((res) => {
      dispatch(setMarkets(res.body));
      return dispatch(loadedMarkets());
    })
    .catch((err) => {
      console.log(err); // eslint-disable-line no-console
      return dispatch(loadedMarkets());
    });
};
