import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import './GuestCountDropdown.css';
import CounterText from './CounterText';

class GuestCountDropdown extends Component {
  static propTypes = {
    disabled: PropTypes.bool.isRequired,
    onValueChanged: PropTypes.func.isRequired,
    classNameProp: PropTypes.string,
    counts: PropTypes.shape({
      adultCount: PropTypes.number,
      teenCount: PropTypes.number,
      infantCount: PropTypes.number,
      childCount: PropTypes.number,
    }),
  };

  static defaultProps = {
    classNameProp: null,
    counts: {
      adultCount: 0,
      teenCount: 0,
      infantCount: 0,
      childCount: 0,
    },
  };

  state = {
    adultCount: this.props.counts.adultCount,
    teenCount: this.props.counts.teenCount,
    infantCount: this.props.counts.infantCount,
    childCount: this.props.counts.childCount,
    isExpanded: false,
  };

  handleButtonPress = () => {
    this.setState((prevState) => ({
      isExpanded: !prevState.isExpanded,
    }));
  }

  handleCountEntered = (value, count) => {
    if (count < 0) return;
    const newState = {};
    newState[value] = count;
    this.setState(newState);
    this.props.onValueChanged(newState);
  }

  /**
   * This handler is called by the onClickOutside React Higher Order Component (HOC)
   * that wraps this component.
   */
  handleClickOutside = () => {
    this.setState({ isExpanded: false });
  };

  totalCount() {
    return this.state.adultCount
      + this.state.teenCount
      + this.state.infantCount
      + this.state.childCount;
  }

  render() {
    return (
      <div className={`count-dropdown-input ${this.props.classNameProp}`}>
        <button
          className="dropdown-toggle"
          type="button"
          onClick={this.handleButtonPress}
          disabled={this.props.disabled}
        >
          <div className="dropdown-placeholder">
            {
              this.totalCount()
                ? (<span>Guests | {this.totalCount()}</span>)
                : (<span>Guests</span>)
            }
          </div>
          <div className="input-caret" />
        </button>
        <div className="dropdown-menu" style={{ visibility: this.state.isExpanded ? 'visible' : 'hidden' }}>
          <div className="arrow" />
          <div className="dropdown-menu-content">
            <div>
              <div className="count-dropdown">
                <div className="count-dropdown-labels">
                  <div className="count-dropdown-label">Adults</div>
                  <div className="count-dropdown-label-secondary">Ages 18+</div>
                </div>
                <CounterText
                  count={this.state.adultCount}
                  value="adultCount"
                  handleCountEntered={this.handleCountEntered}
                />
              </div>
              <div className="count-dropdown">
                <div className="count-dropdown-labels">
                  <div className="count-dropdown-label">Teens</div>
                  <div className="count-dropdown-label-secondary">Ages 13 - 17</div>
                </div>
                <CounterText
                  count={this.state.teenCount}
                  value="teenCount"
                  handleCountEntered={this.handleCountEntered}
                />
              </div>
              <div className="count-dropdown">
                <div className="count-dropdown-labels">
                  <div className="count-dropdown-label">Children</div>
                  <div className="count-dropdown-label-secondary">Ages 2 - 12</div>
                </div>
                <CounterText
                  count={this.state.childCount}
                  value="childCount"
                  handleCountEntered={this.handleCountEntered}
                />
              </div>
              <div className="count-dropdown">
                <div className="count-dropdown-labels">
                  <div className="count-dropdown-label">Infants</div>
                  <div className="count-dropdown-label-secondary">Ages under 2</div>
                </div>
                <CounterText
                  count={this.state.infantCount}
                  value="infantCount"
                  handleCountEntered={this.handleCountEntered}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default onClickOutside(GuestCountDropdown);
