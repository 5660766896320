/* eslint-disable import/prefer-default-export */
import moment from 'moment-timezone';

import restClient from '../../services/util/http';
import { setLeadId, setUserInfo, setLeadError } from '../user/actions';
import { setLead } from '../lead/actions';

const mapPropertyType = (leadSource) => {
  switch (leadSource) {
    case 'Property Management':
    case 'PM':
      return 'vacation_rental';
    case 'Boutique Hotel':
      return 'hotel';
    default:
      return null;
  }
};

export const getLeadBySalesforceID = (id) => (dispatch) => {
  dispatch(setLeadId(id));
  return restClient.get(`/api/questionnaire/lead/${id}`)
    .then((resp) => {
      const lead = resp.body;
      const today = moment(new Date()).tz('UTC');
      const endDate = lead.itinerary_end_date && moment(lead.itinerary_end_date.iso).tz('UTC');
      if (endDate && lead.itineraryId && today.isBefore(endDate)) {
        window.location = `${process.env.ITINERARY_ENDPOINT}/itineraries/${lead.itineraryId}?utm_source=returning_lead`;
        return null;
      }
      const marketName = lead.market && lead.market.name;
      const {
        email,
        first_name: firstName,
        last_name: lastName,
      } = lead;
      const phone = lead.phone || lead.mobile;

      dispatch(setUserInfo({
        username: email,
        firstName,
        lastName,
        phone,
      }));
      if (!lead.itineraryId) {
        dispatch(setLead({
          id,
          marketName,
          startDate: lead.itinerary_start_date
            && moment(lead.itinerary_start_date.iso).toDate().toJSON(),
          endDate: lead.itinerary_end_date
            && moment(lead.itinerary_end_date.iso).toDate().toJSON(),
          propertyType: mapPropertyType(lead.lead_source),
          productLine: lead.product_line,
          partner: lead.property_management
            ? {
              id: lead.property_management.objectId,
              loading: false,
              partnerPage: true,
              isHomesCatalogPartner: lead.property_management.isHomesCatalogPartner,
              logo: lead.property_management.logo,
              homepageLink: lead.property_management.homepage_link,
              status: lead.property_management.salesforce_source.Status__c,
              marketId: lead.property_management.market.objectId,
            }
            : null,
          isGuestLead: lead.isGuestLead,
        }));
      }
      return resp;
    })
    .catch((e) => {
      console.error(e); // eslint-disable-line no-console
      dispatch(setLeadError(e));
    });
};
